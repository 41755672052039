import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base'

declare var require: any

export function ejsDatetimepickerInit() {
  console.log('ejsDatetimepickerInit')

  setCulture('cs')

  loadCldr(
    require('cldr-data/main/cs/numbers.json'),
    require('cldr-data/main/cs/ca-gregorian.json'),
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/cs/timeZoneNames.json'),
    require('cldr-data/supplemental/weekData.json'), // To load the culture based first day of week
  )

  L10n.load({
    cs: {
      datepicker: {
        day: 'd',
        month: 'm',
        year: 'r',
      },
      timepicker: {
        hour: 'hh',
        minute: 'mm',
      },
      datetimepicker: {
        placeholder: 'Vyberte datum a čas',
        today: 'dnes',
      },
      daterangepicker: {
        applyText: 'Vybrat',
        // placeholder:
        startLabel: 'Začátek',
        endLabel: 'Konec',
        cancelText: 'Zrušit',
        selectedDays: 'Počet vybraných dní',
        days: 'd',
        customRange: 'Vlastní',
      },
    },
  })
}
