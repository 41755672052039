import { RRule, VEvent } from 'src/app/shared/configs/rschedule'
import { ICALHelper } from './iCalHelper'
import { StandardDateAdapter } from '@rschedule/standard-date-adapter'
import { DateRange, DateUtils } from '../../services/date-utils.service'
import { RecurrentFeathersModel } from '../../models/RecurrentFeathersModel'
import {
  addHours,
  addMilliseconds,
  addMinutes,
  differenceInHours,
  subDays,
  subHours,
} from 'date-fns'
import { RruleJsHelper } from './rruleJsHelper'

export class RruleHelper {
  private static TAG: string = 'RruleHelper'

  public static computeRecurrenceEndFromCount(
    startDate: Date,
    rule: string,
    maxCount: number = 43,
  ): Date | undefined {
    if (!rule) {
      return null
    }

    const vevent = this.stringRruleToVEvent(startDate, rule)

    if (vevent.isInfinite) {
      return undefined
    }

    const veventOpts = vevent.rrules[0].options

    if (!veventOpts.count && veventOpts.end) {
      // This vEvent doesn't have COUNT, it has UNTIL, so there's no need to compute the UNTIL date
      return new Date(veventOpts.end.toISOString())
    }

    const lastElement = vevent.occurrences().toArray().pop()

    return lastElement.date
  }

  private static stringRruleToVEvent(startDate: Date, rrule: string): VEvent {
    // console.debug(this.TAG, 'stringRuleToVEvent')
    // TODO: should include durations, but do they work in rschedule?

    // rrule = this.ensureRruleLocalISOFormat(rrule)

    const veventStr = this.composeVEventString(startDate, rrule)
    // console.log(this.TAG, 'stringRruleToVEvent, veventStr', veventStr)
    return VEvent.fromICal(veventStr)[0]
  }

  private static expandUsingVevent(parent: RecurrentFeathersModel) {
    const veventStr = RruleHelper.composeVEventString(
      parent.getDateFrom(),
      parent.rrule,
      parent.getDateTo(),
      parent.recurrenceException,
    )

    let vevent
    try {
      vevent = VEvent.fromICal(veventStr)[0]
    } catch (e) {
      console.warn(this.TAG, veventStr, e)
      return []
    }

    // Filter out exceptions
    const exdates = vevent.exdates.occurrences({ take: 50 }).toArray()
    // console.log('exdates', exdates)
    const occurrencesWithoutExceptions: any[] = vevent
      .occurrences({ take: 50 })
      .toArray()
      .filter((o) => {
        for (const ex of exdates) {
          if ((ex.date as Date).getTime() === (o.date as Date).getTime()) {
            // if (DateUtils.formatAsUtcYMD(ex.date) === DateUtils.formatAsUtcYMD(o.date))
            return false
          }
        }
        return true
      })

    return occurrencesWithoutExceptions
  }

  /**
   * Expands a recurrent feathers model into an array of dates using RruleJsHelper.
   *
   * @param {RecurrentFeathersModel} parent - The parent recurrent feathers model.
   * @param {DateRange} [range] - The date range to filter the expansion. Optional. Defaults to null.
   * @returns {Date[]} - An array of expanded dates.
   */
  static expandRecurrentFeathersModel(
    parent: RecurrentFeathersModel,
    range?: DateRange,
  ): Date[] {
    return RruleJsHelper.expand(parent, range)
  }

  private static composeVEventString(
    dtStart: Date,
    rrule: string,
    dtEnd?: Date,
    exdate?: string,
  ) {
    // console.debug(this.TAG, 'composeVEventString')
    // `DTSTART:${this.dateToIcal(parent.dt_start)}\nRRULE:${parent.rrule}\nEXDATE:${parent.recurrenceException}\nDURATION:${new Date(parent.dt_end).getTime() - new Date(parent.dt_start).getTime()}`
    let duration = null
    if (dtStart && dtEnd) {
      duration = ICALHelper.getIcalDuration(
        new Date(dtEnd).getTime() - new Date(dtStart).getTime(),
      )
    }

    let res = 'DTSTART:' + this.dateToIcal(dtStart)
    res = exdate ? res + '\nEXDATE:' + exdate : res
    res = duration ? res + '\nDURATION:' + duration : res
    res = rrule ? res + '\nRRULE:' + rrule : res

    // res = 'DTSTART:20191209T230000000Z\nRRULE:FREQ=DAILY;INTERVAL=1;'

    return res
  }

  // TODO: methods below should be handled in ICALHelper by ical.js
  // based on https://stackoverflow.com/questions/8657958/how-to-parse-calendar-file-dates-with-javascript
  public static icalToDate(icalStr: string, forceTimezone?: Date): Date {
    // console.debug(this.TAG, 'icalToDate')
    // icalStr = '20110914T184000Z'
    const strYear = Number(icalStr.substr(0, 4))
    const strMonth = parseInt(icalStr.substr(4, 2), 10) - 1
    // const strMonth = parseInt(icalStr.substr(4, 2), 10)
    const strDay = Number(icalStr.substr(6, 2))
    const strHour = Number(icalStr.substr(9, 2))
    const strMin = Number(icalStr.substr(11, 2))
    const strSec = Number(icalStr.substr(13, 2))

    // Add timezone
    const utcDate = new Date(
      Date.UTC(strYear, strMonth, strDay, strHour, strMin, strSec),
    )
    let tzOffset = utcDate.getTimezoneOffset() / 60

    if (forceTimezone) {
      const dateTzOffset = tzOffset
      const forceDateTzOffset = forceTimezone.getTimezoneOffset() / 60
      const forceTzOffsetDiff =
        Math.abs(dateTzOffset) - Math.abs(forceDateTzOffset)
      if (forceTzOffsetDiff != 0) {
        tzOffset = tzOffset + forceTimezone.getTimezoneOffset() / 60
        tzOffset = forceTzOffsetDiff
        return new Date(
          Date.UTC(
            strYear,
            strMonth,
            strDay,
            strHour + tzOffset,
            strMin,
            strSec,
          ),
        )
      }
    }

    return utcDate

    // const date =  new Date(strYear, strMonth, strDay, strHour - tzOffset, strMin, strSec)

    // return date
  }

  public static dateToIcal(date: Date): string {
    // console.debug(this.TAG, 'dateToIcal')
    // console.log(date)
    let icalStr = this.dateToLocalISO(date)
    // console.log(icalStr)
    icalStr = icalStr.replace(/[-:.]/g, '')
    return icalStr
  }

  public static dateToUtcIcal(
    date: Date,
    includeMilliseconds: boolean = false,
  ): string {
    // console.debug(this.TAG, 'dateToUtcIcal')
    let icalStr = date.toISOString()
    // console.log(icalStr)
    icalStr = icalStr.replace(/\d{3}Z/g, 'Z')
    icalStr = icalStr.replace(/[-:.]/g, '')
    return icalStr
  }

  public static isoDateToIcal(isoDate: string) {
    // Also removes milliseconds
    return (isoDate.split('.')[0] + 'Z').replace(/[-:.]/g, '')
  }

  public static dateToLocalISO(date) {
    // console.debug(this.TAG, 'dateToLocalISO', date)
    const off = date.getTimezoneOffset()
    const absoff = Math.abs(off)
    return (
      new Date(date.getTime() - off * 60 * 1000).toISOString().substr(0, 23) +
      (off > 0 ? '-' : '+') +
      (absoff / 60).toFixed(0).padStart(2, '0') +
      ':' +
      (absoff % 60).toString().padStart(2, '0')
    )
  }

  public static recurrenceExceptionListContains(
    recurrenceExceptionList: string,
    exceptionToCheck: Date,
  ): boolean {
    if (!exceptionToCheck) {
      return false
    }

    const dates = this.convertRecurrenceExceptionListToDateArray(
      recurrenceExceptionList,
    )

    const filteredDates = dates.filter((date) => {
      return date.getTime() === exceptionToCheck.getTime()
      // return DateUtils.formatAsUtcYMD(date) === DateUtils.formatAsUtcYMD(exceptionToCheck)
    })

    return filteredDates.length > 0
  }

  public static replaceRecurrenceException(
    recurrenceExceptionList: string,
    exceptionToReplace: Date,
    exceptionToBeReplacedWith: Date,
  ): string {
    // console.log('exceptionToReplace', exceptionToReplace);
    // console.log('exceptionToBeReplacedWith', exceptionToBeReplacedWith);
    const exceptions = this.convertRecurrenceExceptionListToDateArray(
      recurrenceExceptionList,
    ).map((e) => {
      // console.log('exception', e);
      if (e.getTime() === exceptionToReplace.getTime()) {
        // console.log(`replacing`, e, `with`, exceptionToBeReplacedWith)
        return exceptionToBeReplacedWith
      }
      return e
    })

    return this.convertDateArrayToRecurrenceExceptionList(exceptions)
  }

  public static isLastExceptionOrLater(
    recurrenceExceptionList: string,
    dateToCheck: Date,
  ): boolean {
    if (!recurrenceExceptionList || recurrenceExceptionList.length === 0) {
      return true
    }

    const dates = this.convertRecurrenceExceptionListToDateArray(
      recurrenceExceptionList,
    )
    const lastExceptionInList = DateUtils.getMaxDate(dates)

    return dateToCheck.getTime() >= lastExceptionInList.getTime()
    // return DateUtils.formatAsUtcYMD(dateToCheck) >= DateUtils.formatAsUtcYMD(lastExceptionInList);
  }

  public static addRecurrenceException(
    recurrenceExceptionList: string,
    exceptionToAdd: Date,
  ): string {
    if (
      this.recurrenceExceptionListContains(
        recurrenceExceptionList,
        exceptionToAdd,
      )
    ) {
      return recurrenceExceptionList
    }

    console.log('exceptionToAdd', exceptionToAdd)
    console.log('exceptionToAdd.toISOString()', exceptionToAdd.toISOString())
    const stringExceptionToAdd: string = this.isoDateToIcal(
      exceptionToAdd.toISOString(),
    )

    const exceptionArray =
      recurrenceExceptionList && recurrenceExceptionList.length > 0
        ? recurrenceExceptionList.split(',')
        : []

    exceptionArray.push(stringExceptionToAdd)
    return exceptionArray.join(',')
  }

  public static combineRecurrenceExceptionLists(
    list1: string,
    list2: string,
  ): string {
    const array2 = list2 && list2.length > 0 ? list2.split(',') : []

    let res: string
    array2.forEach(
      (e) => (res = this.addRecurrenceException(list1, this.icalToDate(e))),
    )

    return res
  }

  public static removeRecurrenceException(
    recurrenceExceptionList: string,
    dateToRemove: Date,
  ): string {
    if (!recurrenceExceptionList || recurrenceExceptionList.length === 0) {
      return ''
    }

    let exceptionArray =
      recurrenceExceptionList && recurrenceExceptionList.length > 0
        ? recurrenceExceptionList.split(',')
        : []

    exceptionArray = exceptionArray.filter((item) => {
      return this.icalToDate(item).getTime() !== dateToRemove.getTime()
      // return DateUtils.formatAsUtcYMD(this.icalToDate(item)) !== DateUtils.formatAsUtcYMD(dateToRemove)
    })

    return exceptionArray.join(',')
  }

  public static splitRecurrenceExceptionsByDate(
    recurrenceExceptionList: string,
    splitDate: Date,
  ): { newer: string; older: string } {
    const dates = this.convertRecurrenceExceptionListToDateArray(
      recurrenceExceptionList,
    )
    const splitDates = {
      newer: [],
      older: [],
    }
    const res = {
      newer: '',
      older: '',
    }

    dates.map((d) =>
      d < splitDate ? splitDates.older.push(d) : splitDates.newer.push(d),
    )

    res.newer = this.convertDateArrayToRecurrenceExceptionList(splitDates.newer)
    res.older = this.convertDateArrayToRecurrenceExceptionList(splitDates.older)

    return res
  }

  public static splitRecurrenceExceptionsByDateAndShift(
    recurrenceExceptionList: string,
    splitDate: Date,
    oldDtStart: Date,
    newDtStart: Date,
  ) {
    console.log(
      'splitRecurrenceExceptionsByDateAndShift',
      recurrenceExceptionList,
      splitDate,
      oldDtStart,
      newDtStart,
    )
    const res = this.splitRecurrenceExceptionsByDate(
      recurrenceExceptionList,
      splitDate,
    )

    const diffMs = DateUtils.differenceOfHoursInMs(oldDtStart, newDtStart)

    // console.log('diffMs', diffMs)
    res.newer = this.shiftRecurrenceExceptionsByMs(res.newer, diffMs)

    // console.log('res', res)

    return res
  }

  /**
   * Changes UNTIL date on a rrule to desiredEndDate. Needs recurrenceStartDate because internally it's constructing a disposable VEvent.
   * @param rrule
   * @param recurrenceStartDate
   * @param desiredEndDate
   */
  public static changeRruleEndDate(
    rrule: string,
    recurrenceStartDate: Date,
    desiredEndDate: Date,
  ) {
    // console.log(this.TAG, 'changeEndDate', rrule, recurrenceStartDate, desiredEndDate)

    // if (recurrenceStartDate.getTime() === desiredEndDate.getTime()) { return '' }

    rrule = this.stripUntilFromRrule(rrule)
    const ruleOptions = this.stringRruleToVEvent(recurrenceStartDate, rrule)
      .rrules[0].options
    ruleOptions.count = undefined
    ruleOptions.end = new StandardDateAdapter(desiredEndDate, {
      timezone: 'UTC',
    })

    // console.log(this.TAG, 'changeEndDate ruleoptions', ruleOptions)

    const newVEvent = new VEvent({
      start: recurrenceStartDate,
      rrules: [new RRule(ruleOptions)],
    }).set('timezone', 'UTC')

    // console.log(this.TAG, 'changeEndDate newVevent', newVEvent)

    const iCalVevent = newVEvent.toICal()
    // console.log(this.TAG, 'changeEndDate newVevent to ical', iCalVevent)

    const fixedICalVevent =
      this.workaroundRScheduleIssueWithExtraLineBreakInUntilRrule(iCalVevent)
    // console.log(this.TAG, 'changeEndDate newVevent to icalFixed', fixedICalVevent)

    return this.getRruleFromIcalVevent(fixedICalVevent)
  }

  private static convertRecurrenceExceptionListToDateArray(
    recurrenceExceptionList: string,
    keepDtStartTimezone?: Date,
  ): Date[] {
    // console.debug(this.TAG, 'convertRecurrenceExceptionListToDateArray')
    if (!recurrenceExceptionList) {
      return []
    }
    const dateList: Date[] = []
    recurrenceExceptionList.split(',').forEach((item) => {
      dateList.push(RruleHelper.icalToDate(item, keepDtStartTimezone))
    })
    return dateList
  }

  static convertDateArrayToRecurrenceExceptionList(dates: Date[]) {
    return dates.map((d) => this.dateToRecurrenceExceptionFormat(d)).join(',')
  }

  static dateToRecurrenceExceptionFormat(date: Date) {
    return this.dateToUtcIcal(date)
  }

  /**
   * Parses string Vevent and returns Rrule
   * @param vevent string of the following format:
   *  BEGIN:VEVENT
   *  DTSTART:20200213T080000
   *  RRULE:FREQ=DAILY;UNTIL=20200210T075547;INTERVAL=1
   *  END:VEVENT
   */
  private static getRruleFromIcalVevent(vevent: string) {
    // console.debug(this.TAG, 'getRruleFromIcalVevent')
    const regex = /^RRULE:(.*)$/m
    let rrule = vevent.match(regex)[1]
    if (!rrule.endsWith(';')) {
      rrule = rrule + ';'
    }
    return rrule
  }

  // static isAssistanceFirstInSeries(assistance: Assistance, recurrenceStartDate: Date) {
  //   console.debug(this.TAG, 'isFirstInSeries', assistance, recurrenceStartDate)
  //   if (!recurrenceStartDate) { return false }
  //   return DateUtils.formatAsUtcYMD(new Date(assistance.plannedFrom)) === DateUtils.formatAsUtcYMD(recurrenceStartDate)
  // }
  // static isAssistantAvailabilityFirstInSeries(aa: AssistantAvailability, recurrenceStartDate: Date) {
  //   console.debug(this.TAG, 'isFirstInSeries', aa, recurrenceStartDate)
  //   if (!recurrenceStartDate) { return false }
  //   return DateUtils.formatAsUtcYMD(new Date(aa.dt_start)) === DateUtils.formatAsUtcYMD(recurrenceStartDate)
  // }

  static isRecurrentFeathersModelFirstInSeries(
    recurrable: RecurrentFeathersModel,
    recurrenceStartDate: Date,
  ) {
    // console.debug(this.TAG, 'isFirstInSeries', recurrable, recurrenceStartDate)
    if (!recurrenceStartDate) {
      return false
    }
    return recurrable.getDateFrom().getTime() === recurrenceStartDate.getTime()
    // return DateUtils.formatAsUtcYMD(recurrable.getDateFrom()) === DateUtils.formatAsUtcYMD(recurrenceStartDate)
  }

  static extractUntilDateFromStringRrule(rrule: string): Date | undefined {
    // * RRULE:FREQ=DAILY;UNTIL=20200210T075547;INTERVAL=1
    const regex = /UNTIL=([0-9TZ+]*)/
    const until = rrule.match(regex)
    if (!until) {
      return undefined
    }
    return this.icalToDate(until[1])
  }

  private static stripUntilFromRrule(rrule: string): string {
    return this.replaceRruleUntil(rrule)
  }

  static replaceRruleUntil(rrule: string, newDate?: Date): string {
    return rrule.replace(
      /(UNTIL=)(.*?)(;|$|\n)/,
      newDate ? `$1${this.dateToUtcIcal(newDate)}$3` : '',
    )
  }

  static changeTimeInRruleUntil(
    rrule: string,
    desiredUtcTimeHours: number,
    desiredUtcTimeMinutes = 0,
  ) {
    // Extract date from rrule until, convert it to UTC
    const localUntilDate = this.extractUntilDateFromStringRrule(rrule)
    if (!localUntilDate) return rrule // If rrule has COUNT, it has no UNTIL date

    // Change hours and minutes
    if (desiredUtcTimeHours < 0) {
      localUntilDate.setUTCHours(
        desiredUtcTimeHours + 24,
        desiredUtcTimeMinutes,
        0,
      )
    } else {
      localUntilDate.setUTCHours(desiredUtcTimeHours, desiredUtcTimeMinutes, 0)
    }

    // Join back the rrule and return
    const newRrule = this.changeRruleEndDate(rrule, new Date(0), localUntilDate)

    return newRrule
  }

  static addDTSTARTToRrule(dtStart: Date, rrule) {
    // return `DTSTART:${this.dateToLocalISO(dtStart)}\nRRULE:${rrule}`
    return `DTSTART:${this.dateToUtcIcal(dtStart)}\nRRULE:${rrule}`
  }

  static trim(rrule: string) {
    if (rrule.endsWith(';')) {
      return rrule.substr(0, rrule.length - 1)
    }
    return rrule
  }

  static addEXDATEStoRrule(
    rrule: string,
    exceptionList,
    dtStartToKeepTimezone?: Date,
  ) {
    const exdates = this.convertRecurrenceExceptionListToDateArray(
      exceptionList,
      dtStartToKeepTimezone,
    )
    for (const x of exdates) {
      rrule = rrule
        .concat('\nEXDATE:')
        .concat(this.dateToRecurrenceExceptionFormat(x))
    }

    return rrule
  }

  static generateRecurrenceExceptionForParent(
    childOldDateStart: Date,
    parentDateStart: Date,
    currentRExceptionsList: string,
  ) {
    // console.log('generateRecurrenceExceptionForParent', childDateStart)

    let exceptionDateTime = new Date(parentDateStart)

    // console.log('exceptionDateTime', exceptionDateTime)
    exceptionDateTime.setFullYear(
      childOldDateStart.getFullYear(),
      childOldDateStart.getMonth(),
      childOldDateStart.getDate(),
    )
    // console.log('exceptionDateTime after set hours', exceptionDateTime)

    return RruleHelper.addRecurrenceException(
      currentRExceptionsList,
      exceptionDateTime,
    )
  }

  private static shiftRecurrenceExceptionsByMs(
    rExceptionList: string,
    ms: number,
  ) {
    const dates = this.convertRecurrenceExceptionListToDateArray(
      rExceptionList,
    ).map((d) => addMilliseconds(d, ms))
    // console.log('shiftRecurrenceExceptionsByMs dates', dates)
    return this.convertDateArrayToRecurrenceExceptionList(dates)
  }

  /**
   * This is a workaround for bug https://gitlab.com/john.carroll.p/rschedule/-/issues/48
   * @param iCalVevent
   * @private
   */
  private static workaroundRScheduleIssueWithExtraLineBreakInUntilRrule(
    iCalVevent: string,
  ) {
    // console.log('workaroundRScheduleIssueWithExtraLineBreakInUntilRrule', iCalVevent);
    const fixedICalVevent = iCalVevent.replace('\n ', '')

    return fixedICalVevent
  }

  static changeTimezoneWithoutChangingTimeValue(target: Date, source: Date) {
    const targetIso = this.dateToLocalISO(target)
    const sourceIso = this.dateToLocalISO(source)

    const sourceTz = sourceIso.slice(-6, sourceIso.length)
    const changedTarget = targetIso.slice(0, -6) + sourceTz

    // console.log(sourceIso)
    // console.log(sourceTz)
    // console.log(changedTarget)

    return new Date(changedTarget)
  }

  static shiftAllRecurrenceExceptions(
    recurrenceExceptionsList: string,
    byHours: number,
  ): string {
    if (recurrenceExceptionsList.length == 0) return recurrenceExceptionsList

    const dateList: Date[] =
      RruleHelper.convertRecurrenceExceptionListToDateArray(
        recurrenceExceptionsList,
      )

    const shiftedList: Date[] = []
    for (const d of dateList) {
      shiftedList.push(addHours(d, byHours))
    }

    return this.convertDateArrayToRecurrenceExceptionList(shiftedList)
  }

  static shiftRruleUntil(rrule: string, byMinutes: number): string {
    const untilDate: Date = this.extractUntilDateFromStringRrule(rrule)
    const shiftedDate: Date = addMinutes(untilDate, byMinutes)

    return this.replaceRruleUntil(rrule, shiftedDate)
  }

  static adjustUNTILtimezoneToDtStart(rrule: string, dateFrom: Date) {
    const untilDate: Date = this.extractUntilDateFromStringRrule(rrule)

    if (!untilDate) return rrule

    const shiftMinutes = this.getTimezoneOffsetAbsMinutes(dateFrom, untilDate)

    return this.shiftRruleUntil(rrule, shiftMinutes)
  }

  static getTimezoneOffsetAbsMinutes(d1: Date, d2: Date) {
    return Math.abs(
      Math.abs(d1.getTimezoneOffset()) - Math.abs(d2.getTimezoneOffset()),
    )
  }
}
