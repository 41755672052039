import { Injectable } from '@angular/core'
import { fromEvent, merge, Observable } from 'rxjs'
import {
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
} from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class AppVisibilityService {
  private readonly hidden: string
  private visibilityChange: string
  visibilityChange$: Observable<boolean>

  constructor() {
    if (typeof document.hidden !== 'undefined') {
      this.hidden = 'hidden'
      this.visibilityChange = 'visibilitychange'
    } else if (typeof (document as any).msHidden !== 'undefined') {
      this.hidden = 'msHidden'
      this.visibilityChange = 'msvisibilitychange'
    } else if (typeof (document as any).webkitHidden !== 'undefined') {
      this.hidden = 'webkitHidden'
      this.visibilityChange = 'webkitvisibilitychange'
    }

    this.visibilityChange$ = merge(
      fromEvent(document, this.visibilityChange),
      fromEvent(window, 'blur'),
      fromEvent(window, 'focus'),
    ).pipe(
      startWith(0),
      map(() => this.getIsVisible()),
      distinctUntilChanged(),
      shareReplay(1),
    )
  }

  private getIsVisible(): boolean {
    return !document[this.hidden]
  }
}
