import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import { registerLicense } from '@syncfusion/ej2-base'

registerLicense(
  'ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkxiXX5fc3NXTmdbVUw=',
)

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)

if (environment.production) {
  enableProdMode()
  bootstrap().catch((err) => console.log(err))
} else {
  bootstrap().catch((err) => console.log(err))

  // we enable RXjs Spy on non production bulds only
  // const spy = create();
  // we call show for two purposes: first is to log to the console an empty snapshot so we can see that everything is working as expected, then to suppress unused variable usage (the latter is a convention on mine)
  // spy.show();
}
