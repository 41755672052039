import { FeathersDataStoreHolder } from './feathers.service'
import { NewableFeathersModel } from '../shared/utils/typeUtils'
import { RecurrentFeathersModel } from '../models/RecurrentFeathersModel'
import { FeathersDataStore } from './feathersDataStore'

export class FeathersRecurrentDataStore<
  T extends RecurrentFeathersModel,
> extends FeathersDataStore<T> {
  defaultStoreValue: T[] = []

  constructor(
    protected recordClass: NewableFeathersModel<T>,
    protected feathersService: any,
    dataStoreHolder: FeathersDataStoreHolder,
  ) {
    super(recordClass, feathersService, dataStoreHolder)
  }

  protected onCreated(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onCreated`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    if (record == null) {
      console.log('Null record returned')
      return
    }
    const index = this.getIndex(record.URID)
    if (index >= 0) {
      this.store[index] = this.mapToModel(record, 'create')
    } else {
      this.store?.push(this.mapToModel(record, 'create'))
    }
    return this.store
  }
  protected onUpdated(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onUpdated record:`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    const index = this.getIndex(record.URID)
    if (index >= 0) {
      this.store[index] = this.mapToModel(record, 'update')
    } else {
      this.store.push(this.mapToModel(record, 'update'))
    }
    return this.store
  }
  protected onPatched(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onPatched record:`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    const index = this.getIndex(record.URID)
    if (index >= 0) {
      this.store[index] = this.mapToModel(record, 'patch')
      return this.store
    }
  }
  protected onRemoved(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onRemoved record:`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    const index = this.getIndex(record.URID)
    if (index >= 0) {
      this.store.splice(index, 1)
      return this.store
    }
  }

  protected getIndex(urid: string): number {
    console.log('DataStore#getIndex', this.id, this.store, this)
    if (this.store) {
      for (const item of this.store) {
        if (item.URID === urid) {
          return this.store.indexOf(item)
        }
      }
    }
    return -1
  }
}
