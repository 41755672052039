import { FeathersModel } from './feathersModel'
import { DateRange } from '../services/date-utils.service'
import { RecurrenceUtil } from '../features/recurrence/RecurrenceUtil'
import { RruleHelper } from '../features/recurrence/rruleHelper'
import { Recurrable } from '../features/recurrence/Recurrable'
import {
  addMilliseconds,
  differenceInMilliseconds,
  isSameMinute,
} from 'date-fns'

export abstract class RecurrentFeathersModel
  extends FeathersModel
  implements Recurrable
{
  constructor(id: number | null) {
    super(id)
  }

  get recurrenceEnd(): Date {
    if (!this.rrule) {
      return
    }
    return RruleHelper.extractUntilDateFromStringRrule(this.rrule)
  }
  set recurrenceEnd(value) {
    return
  }

  get URID() {
    return RecurrenceUtil.generateURID(this)
  }

  get durationMs() {
    return differenceInMilliseconds(this.getDateTo(), this.getDateFrom())
  }

  rrule: string
  recurrenceParentId: number
  recurrenceException: string
  recurrenceExceptionDate: Date

  static isRecurrent(r: RecurrentFeathersModel): boolean {
    return !!r.rrule || !!r.recurrenceParentId
  }

  static isRecurrentButNotException(r: RecurrentFeathersModel): boolean {
    return (
      RecurrentFeathersModel.isRecurrent(r) &&
      !RecurrentFeathersModel.isRecurrenceException(r)
    )
  }

  static isRecurrenceException(r: RecurrentFeathersModel): boolean {
    return !r.rrule && !r.recurrenceException && !!r.recurrenceParentId
  }

  public static sortByStartDate(
    a: RecurrentFeathersModel,
    b: RecurrentFeathersModel,
  ) {
    if (a.getDateFrom() < b.getDateFrom()) {
      return -1
    }
    return 1
  }

  abstract getDateFrom(): Date
  abstract getDateTo(): Date

  updateFromPropsToCreateRecurrenceException(props: RecurrentFeathersModel) {
    props.recurrenceException = null
    props.rrule = null
    return this.updateFromProps(props)
  }

  public isRecurrent(): boolean {
    return RecurrentFeathersModel.isRecurrent(this)
  }

  public isRecurrentButNotException(): boolean {
    return RecurrentFeathersModel.isRecurrentButNotException(this)
  }

  public isRecurrenceException(): boolean {
    return RecurrentFeathersModel.isRecurrenceException(this)
  }

  public isFirstInRecurrentSeries() {
    return !!this.rrule && !this.recurrenceParentId
  }

  public isExpanded() {
    return !!this.recurrenceParentId && !this.id
  }

  public getPlannedRange(): DateRange {
    return { from: this.getDateFrom(), to: this.getDateTo() }
  }

  abstract generateFirstOccurrence()
  abstract generateOccurrence(
    occurrenceStartDate: Date,
    occurrenceEndDate: Date,
  )

  expand(range?: DateRange, expandParentAsOccurrence = true): this[] {
    const expanded: this[] = []

    const occurrenceStartDates: Date[] =
      RruleHelper.expandRecurrentFeathersModel(this, range)

    occurrenceStartDates.forEach((occurrenceStartDate) => {
      if (
        !expandParentAsOccurrence &&
        isSameMinute(occurrenceStartDate, this.getDateFrom())
      ) {
        // first occurrence in the series
        expanded.push(this.generateFirstOccurrence())
      } else {
        expanded.push(
          this.generateOccurrence(
            occurrenceStartDate,
            addMilliseconds(occurrenceStartDate, this.durationMs),
          ),
        )
      }
    })
    return expanded
  }

  /**
   * Expands a recurrent assistance by its RRULEs, returning the array of all expanded occurrences. Optionally the parent can be included in the array in place of the first occurrence, if it would occur on the same date..
   * @param parent Assistance, may or may not be recurrent.
   * @param range If not supplied, the underlying functions govern the range (+- 1 year at the time of writing, but check to be sure)
   * @param expandParentAsOccurrence If false, parent is not expanded and the result includes the parent as-is in the array. If true, the parent is not included in the array, but an expanded version is.
   */
  static expandByRrules<T extends RecurrentFeathersModel>(
    parent: RecurrentFeathersModel,
    range?: DateRange,
    expandParentAsOccurrence = true,
  ): T[] {
    const expanded: T[] = []

    const occurenceStartDates: Date[] =
      RruleHelper.expandRecurrentFeathersModel(parent, range)

    const parentStartDate = parent.getDateFrom()
    const parentDurationMs =
      parent.getDateTo().getTime() - parent.getDateFrom().getTime()

    // console.debug('expandAssistanceByRrules occurrenceStartDate', occurenceStartDates, parent)

    occurenceStartDates.forEach((occurrenceStartDate) => {
      // console.log(occurrenceStartDate)

      if (
        !expandParentAsOccurrence &&
        isSameMinute(occurrenceStartDate, parentStartDate)
      ) {
        // Means that current occurrence is the first occurrence in the series
        expanded.push(parent.generateFirstOccurrence())
        // expanded.push(Assistance.generateFirstOccurrence(parent))
      } else {
        expanded.push(
          parent.generateOccurrence(
            occurrenceStartDate,
            addMilliseconds(occurrenceStartDate, parentDurationMs),
          ),
        )
      }
    })

    return expanded
  }
}
