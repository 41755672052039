import { Observable, Observer, Subscription } from 'rxjs'
import { debounceTime, first } from 'rxjs/operators'
import { FeathersRecord } from './feathers.service'

export enum DataSubscriberMode {
  SUBSCRIBE,
  TAKEONCE,
  GETOBSERVABLE,
}

export class DataSubscriber<T extends FeathersRecord> {
  private dataStore: {
    records: T[]
  }
  // asi by slo zjednodusit kdybychom tyhle dva prevedli na behavioursubject??
  private records$: Observable<T[]>
  private observer: Observer<T[]>
  private feathersService: any
  private subscription: Subscription
  private mode: DataSubscriberMode
  // protected query: {};

  constructor(
    feathersService: any,
    cbData: (records: any) => void,
    cbErr: (err: any) => void,
    mode: DataSubscriberMode = DataSubscriberMode.SUBSCRIBE,
  ) {
    this.feathersService = feathersService
    this.feathersService.on('created', (record) => this.onCreated(record))
    this.feathersService.on('updated', (record) => this.onUpdated(record))
    // this.feathersService.on('patched', record => this.onPatched(record))
    this.feathersService.on('removed', (record) => this.onRemoved(record))

    this.records$ = new Observable((obs) => (this.observer = obs))
    this.dataStore = { records: [] }

    this.mode = mode
    switch (mode) {
      case DataSubscriberMode.SUBSCRIBE:
        this.subscription = this.records$.subscribe(cbData, cbErr)
        break
      case DataSubscriberMode.TAKEONCE:
        this.subscription = this.records$.pipe(first()).subscribe(cbData, cbErr)
        break
      case DataSubscriberMode.GETOBSERVABLE:
        this.subscription = this.records$.subscribe()
        break
      default:
        break
    }
  }

  public find(query, service) {
    // console.trace(service, 'observer', this.observer)
    // this.query = query;
    return this.feathersService
      .find({ query })
      .then((records: any) => {
        // records.data: T[]
        // console.time('find')
        console.log(`On find ${service}:`, new Date().getTime(), records)
        this.dataStore.records = records?.data
        console.log(
          `Records in dataStore - ${service}:`,
          this.dataStore.records,
        )
        this.observer.next(this.dataStore.records)
        // console.timeEnd('find')
      })
      .catch((err) => {
        console.trace(service)
        console.error(
          'Error in FeathersService find: %o query: %o',
          err,
          query,
          service,
        )
      })
  }

  public getObservable(): Observable<T[]> {
    if (this.mode === DataSubscriberMode.GETOBSERVABLE) {
      // console.log('getObservable, DataSubscriber: ', this)
      return this.records$.pipe(debounceTime(150)) // TODO why is this here? It slows down everything by 150ms...
    } else {
      throw new Error(
        'Tried to get observable without setting DataSubscriber mode to GETOBSERVABLE',
      )
    }
  }

  public unsubscribe() {
    console.trace('Feathers unsubscribe')
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  private getIndex(id: number): number {
    let foundIndex = -1

    for (let i = 0; i < this.dataStore.records.length; i++) {
      if (this.dataStore.records[i].id === id) {
        foundIndex = i
      }
    }

    return foundIndex
  }

  // TODO: This currently pushes any newly created record on a given service to the dataStore, regardless of query. We should somehow filter out non-matches.
  private onCreated(record: T) {
    console.log('onCreated', record)
    if (record == null) {
      console.log('Null record returned')
      return
    }
    this.dataStore.records.push(record)
    this.observer.next(this.dataStore.records)
  }

  private onUpdated(record: T) {
    console.log('onUpdated record:', record, 'dataStore:', this.dataStore, this)
    const index = this.getIndex(record.id)
    // console.log('index', index)
    if (index >= 0) {
      this.dataStore.records[index] = record
      this.observer.next(this.dataStore.records)
    }
  }

  private onPatched(record: T) {
    console.log('onPatched record:', record, 'dataStore:', this.dataStore, this)
    const index = this.getIndex(record.id)
    if (index >= 0) {
      this.dataStore.records[index] = record
      this.observer.next(this.dataStore.records)
    }
  }

  private onRemoved(record: T) {
    const index = this.getIndex(record.id)
    if (index >= 0) {
      this.dataStore.records.splice(index, 1)
      this.observer.next(this.dataStore.records)
    }
  }
}
