export enum Role {
  Client = 'Klient',
  Admin = 'Admin',
  Superadmin = 'Superadmin',
  Coordinator = 'Koordinátor',
  Assistant = 'Asistent',
  Financial = 'Finanční',
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace RoleGroup {
  export const workers: Role[] = [
    Role.Superadmin,
    Role.Admin,
    Role.Coordinator,
    Role.Financial,
  ]
  export const all: Role[] = Object.values(Role)
}
