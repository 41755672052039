export enum ToggleableModulesName {
  CLIENTS = 'clients',
  ASSISTANTS = 'assistants',
  ASSISTANCES = 'assistances',
  AVAILABILITIES = 'availabilities',
  EXCHANGE = 'exchange',
  FINANCE = 'finance',
  MESSAGING = 'messaging',
  SMS = 'sms',
}

export class ToggleableModules {
  [ToggleableModulesName.CLIENTS] = false;
  [ToggleableModulesName.ASSISTANTS] = false;
  [ToggleableModulesName.ASSISTANCES] = false;
  [ToggleableModulesName.AVAILABILITIES] = false;
  [ToggleableModulesName.EXCHANGE] = false;
  [ToggleableModulesName.FINANCE] = false;
  [ToggleableModulesName.MESSAGING] = false;
  [ToggleableModulesName.SMS] = false

  constructor(values: { [key: string]: boolean } = {}) {
    for (const prop in values) {
      if (!values.hasOwnProperty(prop)) continue
      if (this.hasOwnProperty(prop)) this[prop] = values[prop]
    }
  }

  toJson() {
    return JSON.stringify(this)
  }

  getActiveModulesAsStringArray() {
    const res = []
    const keys = Object.keys(this)

    for (const prop of keys) {
      if (!this.hasOwnProperty(prop)) continue
      if (this[prop] === true) res.push(prop)
    }
    return res
  }
}
