import { FeathersModel } from './feathersModel'
import { Assistance } from './assistance'
import { Contract } from './contract'
import { Assistant } from './assistant'
import { OnlyNumberKeys } from '../shared/utils/typeUtils'
import { Client } from './client'

export class ClientSummaryReport extends FeathersModel {
  static serviceName = 'client-financial-reports'

  clientId: number
  clientFullName: string

  assistanceCount: number

  rateBasicTotalMinutes: number
  rateBasicTotalCZK: number

  rateOvernightTotalMinutes: number
  rateOvernightTotalCZK: number

  rateSleepoverTotalMinutes: number
  rateSleepoverTotalCZK: number

  rateWeekendTotalMinutes: number
  rateWeekendTotalCZK: number

  totalMinutes: number
  totalCZK: number
}

export class AssistantSummaryReport extends FeathersModel {
  static serviceName = 'assistant-financial-reports'

  assistantId: number
  assistantFullName: string

  assistanceCount: number

  rateBasicTotalCZK: number

  bonusShortAssistanceTotalCZK: number
  bonusMorningAssistanceTotalCZK: number
  bonusOvernightAssistanceTotalCZK: number
  bonusWeekendAssistanceTotalCZK: number
  bonusCanceledLateTotalCZK: number
  bonusTransitCZK: number

  customPartCZK: number

  totalBonusCZK: number
  totalMinutes: number
  totalCZK: number
}

export class ClientFinancialReport extends FeathersModel {
  static serviceName = 'client-financial-reports'

  assistanceId: number
  clientId: number
  client?: Client

  clientFullName: string

  rateBasicHourlyCZK: number
  rateBasicMinutes: number
  rateBasicTotalCZK: number

  rateOvernightHourlyCZK: number
  rateOvernightMinutes: number
  rateOvernightTotalCZK: number

  rateSleepoverHourlyCZK: number
  rateSleepoverMinutes: number
  rateSleepoverTotalCZK: number

  rateWeekendHourlyCZK: number
  rateWeekendMinutes: number
  rateWeekendTotalCZK: number

  customPartCZK: number
  customPartReason: string

  isSleepover: boolean = false

  totalMinutes: number
  private _totalCZK: number
  private _invoicedCZK: number = 0

  turnSubresourcesIntoFrontendModels() {
    if (this.client) this.client = FeathersModel.hydrate(Client, this.client)
  }

  get rateBasicHours(): number {
    return this.rateBasicMinutes / 60
  }
  get rateOvernightHours(): number {
    return this.rateOvernightMinutes / 60
  }
  get rateSleepoverHours(): number {
    return this.rateSleepoverMinutes / 60
  }
  get rateWeekendHours(): number {
    return this.rateWeekendMinutes / 60
  }
  get totalHours(): number {
    return this.totalMinutes / 60
  }

  get totalCZK(): number {
    return this._totalCZK
  }
  set totalCZK(value: number) {
    this._totalCZK = value
    this.invoicedCZK = Math.floor(this.totalCZK)
  }
  get invoicedCZK(): number {
    return this._invoicedCZK
  }
  set invoicedCZK(value: number) {
    this._invoicedCZK = value
  }

  static aggregateSum(
    reports: ClientFinancialReport[],
    property: OnlyNumberKeys<ClientFinancialReport>,
  ) {
    if (!reports) return undefined
    return reports.reduce((prev, curr) => {
      if (!curr[property]) return prev
      return prev + curr[property]
    }, 0)
  }

  fillInComputedProperties(payable: boolean) {
    this.rateBasicTotalCZK =
      (this.rateBasicHourlyCZK * this.rateBasicMinutes) / 60
    this.rateOvernightTotalCZK =
      (this.rateOvernightHourlyCZK * this.rateOvernightMinutes) / 60
    this.rateSleepoverTotalCZK =
      (this.rateSleepoverHourlyCZK * this.rateSleepoverMinutes) / 60
    this.rateWeekendTotalCZK =
      (this.rateWeekendHourlyCZK * this.rateWeekendMinutes) / 60
    this.totalMinutes =
      this.rateBasicMinutes +
      this.rateOvernightMinutes +
      this.rateSleepoverMinutes +
      this.rateWeekendMinutes

    if (payable) {
      this.totalCZK =
        this.rateBasicTotalCZK +
        this.rateOvernightTotalCZK +
        this.rateSleepoverTotalCZK +
        this.rateWeekendTotalCZK +
        this.customPartCZK
    } else {
      this.totalCZK = 0
    }

    // if (assistance.isPaidInternally || assistance.status.name === AssistanceStatusName.CANCELED_LATE) {
    //   report.totalCZK = 0
    // } else {
    //   report.totalCZK = report.rateBasicTotalCZK + report.rateOvernightTotalCZK + report.rateSleepoverTotalCZK + report.rateWeekendTotalCZK
    // }

    this.isSleepover = this.rateSleepoverMinutes > 0

    return this
  }
}

export class AssistantFinancialReport extends FeathersModel {
  static serviceName = 'assistant-financial-reports'

  assistanceId: number
  assistance?: Assistance
  assistantId: number
  assistant?: Assistant
  assistantFullName: string
  activeContract: Contract
  activeContractAbbr: string

  rateBasicCZK: number
  rateBasicMinutes: number
  bonusShortAssistanceCZK: number
  bonusMorningAssistanceCZK: number
  bonusOvernightAssistanceCZK: number
  bonusWeekendAssistanceCZK: number
  bonusCanceledLateCZK: number
  bonusTransitCZK: number

  customPartCZK: number
  customPartReason: string

  rateBasicTotalCZK: number
  bonusesTotalCZK: number
  totalCZK: number

  isCanceledLate: boolean = false

  get rateBasicHours(): number {
    return this.rateBasicMinutes / 60
  }

  turnSubresourcesIntoFrontendModels() {
    this.assistant = this.assistant
      ? Assistant.createFromProps(Assistant, this.assistant)
      : this.assistant
  }

  static aggregateSum(
    reports: AssistantFinancialReport[],
    property: OnlyNumberKeys<AssistantFinancialReport>,
  ) {
    if (!reports) return undefined
    return reports.reduce((prev, curr) => {
      if (!curr[property]) return prev
      return prev + curr[property]
    }, 0)
  }

  static getDelimitedAssistantNames(reports: AssistantFinancialReport[]) {
    return reports.map((r) => r.assistant.user.fullName()).join(',')
  }
}

export interface FinancialReporting {
  clientFinancialReports: ClientFinancialReport[]
  assistantFinancialReports: AssistantFinancialReport[]
  backendShouldUpdateFinancialReports: boolean
}

export class AssistanceFinancialReport {
  assistance: Assistance
  clientFinancialReport: ClientFinancialReport
  assistantFinancialReport: AssistantFinancialReport
  paidByAssistance: boolean
  isLateCanceled: boolean

  static aggregateSumForAssistant(
    reports: AssistanceFinancialReport[],
    property: OnlyNumberKeys<AssistantFinancialReport>,
  ): number {
    console.log('aggregateSumForAssistant', reports)
    if (!reports) return 0
    return reports.reduce((prev, curr) => {
      if (!curr.assistantFinancialReport[property]) return prev
      return prev + curr.assistantFinancialReport[property]
    }, 0)
  }
  static aggregateSumForClient(
    reports: AssistanceFinancialReport[],
    property: OnlyNumberKeys<ClientFinancialReport>,
  ): number {
    console.log('aggregateSumForClient', reports)
    if (!reports) return 0
    return reports.reduce((prev, curr) => {
      if (!curr.clientFinancialReport[property]) return prev
      return prev + curr.clientFinancialReport[property]
    }, 0)
  }
}

export class SimpleAssistanceFinancialReport extends FeathersModel {
  static serviceName = 'financial-reports'

  assistanceId: number

  isBilled: boolean
  plannedFrom: string
  plannedTo: string
  realizedFrom: number
  realizedTo: number
  realizedDurationHours: number
  shortDescription: string
  isCanceledLate: boolean
  isSleepover: boolean
  paidByAssistance: boolean

  clientReport: ClientFinancialReport
  assistantReport: AssistantFinancialReport

  turnSubresourcesIntoFrontendModels() {
    this.clientReport = FeathersModel.hydrate(
      ClientFinancialReport,
      this.clientReport,
    )
    // this.clientReport.fillInComputedProperties((!this.isCanceledLate && !this.paidByAssistance))

    this.assistantReport = FeathersModel.hydrate(
      AssistantFinancialReport,
      this.assistantReport,
    )
  }

  getDateFrom(): Date {
    return this.plannedFrom ? new Date(this.plannedFrom) : undefined
  }
  getDateTo(): Date {
    return this.plannedTo ? new Date(this.plannedTo) : undefined
  }
  getRealizedFrom(): Date {
    return this.realizedFrom ? new Date(this.realizedFrom) : undefined
  }
  getRealizedTo(): Date {
    return this.realizedTo ? new Date(this.realizedTo) : undefined
  }
  getRealizedFromWithPlannedFromFallback(): Date {
    if (this.realizedFrom) return new Date(this.realizedFrom)
    if (this.plannedFrom) return new Date(this.plannedFrom)
    return undefined
  }
  getRealizedToWithPlannedToFallback(): Date {
    if (this.realizedTo) return new Date(this.realizedTo)
    if (this.plannedTo) return new Date(this.plannedTo)
    return undefined
  }
}

export class RoleAssistantSummaryReport extends FeathersModel {
  static serviceName = 'assistant-financial-reports'

  assistantId: number
  assistanceId: number

  clientFullName: string
  realizedFrom: Date
  realizedTo: Date

  basicRateTotalCZK: number
  totalBonusCZK: number
  customPartCZK: number
  totalCZK: number
  bonusCanceledLateCZK: number

  totalTimeMinutes: number
}
