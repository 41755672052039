import { NavService } from '../../features/navigation/nav.service'
import { FeathersService } from '../feathers.service'
import { Injectable } from '@angular/core'
import { AdvancedToasterService } from '../../features/notification/advancedToast/advanced-toaster.service'
import {Subject} from 'rxjs'

export type LogoutToast = {
  reason: string
  isPositive: boolean
  durationMs?: number
}

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  public logout$: Subject<void> = new Subject<void>()

  private currentToast: HTMLIonModalElement

  constructor(
    private nav: NavService,
    private toaster: AdvancedToasterService,
  ) {
  }

  public async requestLogout(feathers: FeathersService, toast: LogoutToast) {
    try {
      await feathers.logout()
      feathers.dataStoreHolder.unregisterAllDataStores()
      this.logout$.next()
      this.logout$.complete()
    } finally {
      await this.nav.goToLogin()
      await this.showToast(toast)
    }
  }

  private async showToast(toast: LogoutToast) {
    this.currentToast = await this.toaster.present({
      message: toast.reason,
      ionIconName: toast.isPositive ? 'heart' : 'heart-dislike',
      cssClasses: [
        toast.isPositive ? 'connected-toast' : 'disconnection-toast',
      ],
      durationMs: toast.durationMs ?? 3000,
    })
  }
}
