import { Assistance } from '../../../models/assistance'
import { Client } from '../../../models/client'
import { DateUtils } from '../../../services/date-utils.service'
import { RruleHelper } from '../../recurrence/rruleHelper'
import { EventApi, EventInput } from '@fullcalendar/core'
import { AssistantAvailability } from '../../../models/assistantAvailability'
import { RecurrentFeathersModel } from '../../../models/RecurrentFeathersModel'
import { Assistant } from '../../../models/assistant'
import invert from 'invert-color'
import { ColorUtils } from '../../../shared/utils/colorUtils'
import { AvailabilityCalendarExchangeEnrollment } from '../../assistant-availability/assistant-availability-calendar/AvailabilityCalendarExchangeEnrollment'
import { format } from 'date-fns'

interface FcEventConvertorOpts {
  titleFn?: (a: Assistance) => string
  assistantId?: number
  shrinkEvents?: boolean
}

export enum ResourceSource {
  ASSISTANTS = 'assistants',
  ASSISTANTS_FOR_ENROLLMENTS = 'assistants_for_enrollments',
  CLIENTS = 'clients',
}

export enum EventSource {
  ASSISTANCES = 'assistances',
  AVAILABILITIES = 'availabilities',
  EXCHANGE_ENROLLMENTS = 'exchange_enrollments',
}

export class FullCalendarHelper {
  static mapAssistancesToFCEventsForRoleAssistant(
    assistances: Assistance[],
    opts: FcEventConvertorOpts = {},
  ) {
    if (!opts.assistantId) return []

    if (!opts.titleFn)
      opts.titleFn = (_) => {
        return ''
      }

    return assistances.map((a) => {
      const status = a.assistantAssignments.getAssignmentByAssistantId(
        opts.assistantId,
      ).status

      const event: any = {
        id: a.id.toString(),
        resourceId: FullCalendarHelper.clientIdToResourceId(
          a.clientId,
          ResourceSource.CLIENTS,
        ),
        title: opts.titleFn(a) || '',
        start: a.getDateFrom(),
        end: !opts.shrinkEvents
          ? a.getDateTo()
          : new Date(a.getDateFrom().getTime() + 1000),
        backgroundColor: status.color,
        origin: a,
        extendedProps: {
          eventSource: EventSource.ASSISTANCES,
        },
        textColor: invert(
          ColorUtils.colorNameToHexUsingCanvas(status.color),
          true,
        ),
      }

      if (a.rrule) {
        event.groupId = a.id
        event.rrule = this.generateFCEventRrule(a)
        event.duration = DateUtils.durationMs(
          new Date(a.plannedFrom),
          new Date(a.plannedTo),
        )
      }

      return event
    })
  }

  static mapAssistancesToFCEventsForAvailabilitiesCalendar(
    assistances: Assistance[],
    opts: FcEventConvertorOpts = {},
  ) {
    if (!opts.titleFn) opts.titleFn = (_) => ''

    const events = []

    for (const a of assistances) {
      for (const assignment of a.assistantAssignments.getAssignmentsPrivateArray()) {
        const event: any = {
          id: assignment.id,
          resourceId: FullCalendarHelper.assistantIdToResourceId(
            assignment.assistantId,
            ResourceSource.ASSISTANTS,
          ),
          title: '',
          start: a.getRealizedFrom() || a.getDateFrom(),
          end: a.getRealizedTo() || a.getDateTo(),
          backgroundColor: assignment.status.color,
          className: 'status-' + assignment.status.name,
          origin: assignment,
          textColor: invert(
            ColorUtils.colorNameToHexUsingCanvas(assignment.status.color),
            true,
          ),
          extendedProps: {
            eventSource: EventSource.ASSISTANCES,
            clientName: a.client?.user?.fullname,
            addressStart: a.addressStart,
            addressEnd: a.addressEnd,
          },
        }
        events.push(event)
      }
    }

    console.log('mapAssistancesToFCEventsForAvailabilitiesCalendar', events)

    return events
  }

  static mapAssistancesToFCEvents(
    assistances: Assistance[],
    opts: FcEventConvertorOpts = {},
  ) {
    if (!opts.titleFn)
      opts.titleFn = (_) => {
        return ''
      }

    return assistances.map((a) => {
      const event: any = {
        id: a.id.toString(),
        resourceId: FullCalendarHelper.clientIdToResourceId(
          a.clientId,
          ResourceSource.CLIENTS,
        ),
        title: opts.titleFn(a) || '',
        start: a.getDateFrom(),
        end: a.getDateTo(),
        backgroundColor: a.status.color,
        className: [
          'status-' + a.status.name,
          `cy-status-${a.status.name}`,
          `cy-date-from-${format(a.getDateFrom(), 'y-M-d-H-mm')}`,
          `cy-time-from-${format(a.getDateFrom(), 'H-mm')}`,
        ],
        origin: a,
        textColor: invert(
          ColorUtils.colorNameToHexUsingCanvas(a.status.color),
          true,
        ),
        extendedProps: {
          eventSource: EventSource.ASSISTANCES,
        },
      }

      if (a.rrule) {
        event.groupId = a.id
        event.rrule = this.generateFCEventRrule(a)
        event.duration = DateUtils.durationMs(
          new Date(a.plannedFrom),
          new Date(a.plannedTo),
        )
      }

      return event
    })
  }

  static mapClientsToFCResources(clients: Client[]) {
    return clients.map((c, index) => {
      return {
        index,
        id: FullCalendarHelper.clientIdToResourceId(
          c.id,
          ResourceSource.CLIENTS,
        ),
        title: c.user.fullName(),
        lastName: c.user.lastName, // for sorting resources
        firstName: c.user.firstName, // for sorting resources
        origin: c,
        extendedProps: {
          resourceSource: ResourceSource.CLIENTS,
        },
      }
    })
  }

  static isEventRecurrent(event: EventApi) {
    return !!event.groupId
  }

  static isClickedEventFirstInRecurrenceSeries(
    event: EventApi,
    origin: Assistance | AssistantAvailability,
  ): boolean {
    return (
      event.start.getTime() === origin.getDateFrom().getTime() &&
      event.end.getTime() === origin.getDateTo().getTime()
    )
  }

  static generateRruleStringFromRecurrentModel(
    model: RecurrentFeathersModel,
    isForCalendar = false,
  ): string | null {
    if (!model.rrule) return null

    let rrule = RruleHelper.addDTSTARTToRrule(
      model.getDateFrom(),
      RruleHelper.trim(model.rrule),
    )

    if (isForCalendar) {
      if (model.recurrenceException) {
        rrule = RruleHelper.addEXDATEStoRrule(rrule, model.recurrenceException)
      }

      // TODO Until this bug https://github.com/fullcalendar/fullcalendar/issues/5726 is resolved, we need to use local dates everywhere on the FC rrule
      rrule = this.convertAllRruleUTCDatesToLocalTZ(rrule)
    } else {
      if (model.recurrenceException) {
        rrule = RruleHelper.addEXDATEStoRrule(
          rrule,
          model.recurrenceException,
          model.getDateFrom(),
        )
      }
      rrule = this.convertAllRruleUTCDatesToLocalTZOfDtStart(rrule)

      // Adjust UNTIL if DTSTART is in different TZ than UNTIL
      rrule = RruleHelper.adjustUNTILtimezoneToDtStart(
        rrule,
        model.getDateFrom(),
      )
    }

    return rrule
  }

  static generateFCEventRrule(model: RecurrentFeathersModel): string {
    return this.generateRruleStringFromRecurrentModel(model, true)
  }

  private static convertAllRruleUTCDatesToLocalTZ(rrule: string) {
    let rruleInLocalTz = rrule

    const r = /(\d{8}T)(\d{2})(\d{4})Z/gs

    let match: any[]
    while ((match = r.exec(rrule)) != null) {
      const dateTZoffset =
        RruleHelper.icalToDate(match[0]).getTimezoneOffset() / 60
      const localTzHour = DateUtils.zeropad(Number(match[2]) - dateTZoffset, 2)
      rruleInLocalTz = rruleInLocalTz.replace(
        match[0],
        match[1] + localTzHour + match[3],
      ) // TODO: test for 23 and zero in hours
    }

    return rruleInLocalTz
  }

  private static convertAllRruleUTCDatesToLocalTZOfDtStart(rrule: string) {
    let rruleInLocalTz = rrule

    const r = /(\d{8}T)(\d{2})(\d{4})Z/gs

    let match: any[]
    let dtStartTZOffset: number

    while ((match = r.exec(rrule)) != null) {
      const dateTZoffset =
        RruleHelper.icalToDate(match[0]).getTimezoneOffset() / 60
      if (!dtStartTZOffset) dtStartTZOffset = dateTZoffset
      const localTzHour = DateUtils.zeropad(
        Number(match[2]) - dtStartTZOffset,
        2,
      )
      rruleInLocalTz = rruleInLocalTz.replace(
        match[0],
        match[1] + localTzHour + match[3],
      ) // TODO: test for 23 and zero in hours
    }

    return rruleInLocalTz
  }

  static mapAvailabilitiesToFCEvents(
    assistantAvailabilities: AssistantAvailability[],
  ): EventInput[] {
    return assistantAvailabilities.map((a) => {
      const event: EventInput = {
        id: a.id.toString(),
        resourceId: FullCalendarHelper.assistantIdToResourceId(
          a.assistantId,
          ResourceSource.ASSISTANTS,
        ),
        title: '',
        start: a.getDateFrom(),
        end: a.getDateTo(),
        backgroundColor: getComputedStyle(
          document.documentElement,
        ).getPropertyValue('--app-availability-background-default'),
        origin: a,
        extendedProps: {
          eventSource: EventSource.AVAILABILITIES,
        },
      }

      if (a.rrule) {
        event.groupId = a.id.toString()
        event.rrule = this.generateFCEventRrule(a)
        event.duration = DateUtils.durationMs(a.getDateFrom(), a.getDateTo())
      }

      return event
    })
  }

  static mapAssistantsToFCResources(
    assistants: Assistant[],
    resourceSource = ResourceSource.ASSISTANTS,
  ) {
    return assistants.map((a, index) => {
      return {
        index,
        id: FullCalendarHelper.assistantIdToResourceId(a.id, resourceSource),
        title: a.user.fullName(),
        lastName: a.user.lastName, // for sorting resources
        firstName: a.user.firstName, // for sorting resources
        origin: a,
        extendedProps: {
          resourceSource,
        },
      }
    })
  }

  static mapExchangeEnrollmentsToFCEvents(
    exchangeEnrollments: AvailabilityCalendarExchangeEnrollment[],
  ) {
    return exchangeEnrollments.map((e) => {
      const event: EventInput = {
        id: e.id.toString(),
        resourceId: FullCalendarHelper.assistantIdToResourceId(
          e.assistantId,
          ResourceSource.ASSISTANTS_FOR_ENROLLMENTS,
        ),
        title: '',
        start: e.start,
        end: e.end,
        className: [e.enrolledAt ? 'is-enrolled' : 'is-unenrolled'],
        origin: e,
        extendedProps: {
          eventSource: EventSource.EXCHANGE_ENROLLMENTS,
          clientName: e.assistance.client?.user?.fullname,
          addressStart: e.assistance.addressStart,
          addressEnd: e.assistance.addressEnd,
          isCanceled: !e.enrolledAt,
        },
      }
      console.log('mapExchangeEnrollmentsToFCEvents', event)
      return event
    })
  }

  /**
   * Converts resource id in form 'assistantId-resourcesource' to 'assistantId'
   */
  static resourceIdToAssistantId(resourceId: string): number {
    return Number(resourceId.split('-')[0])
  }
  static resourceIdToClientId(resourceId: string): number {
    return this.resourceIdToAssistantId(resourceId)
  }

  /**
   * Converts assistant id to resourceId in form 'assistantId-resourcesource'
   */
  static assistantIdToResourceId(
    assistantId: number,
    resourceSource: ResourceSource,
  ) {
    return `${assistantId.toString()}-${resourceSource}`
  }
  static clientIdToResourceId(
    clientId: number,
    resourceSource: ResourceSource,
  ) {
    return this.assistantIdToResourceId(clientId, resourceSource)
  }
}
