import { Injectable } from '@angular/core'
import { FeathersService } from './feathers.service'
import { Observable } from 'rxjs'
import { first, map, shareReplay, switchMap, tap } from 'rxjs/operators'
import { Config, ConfigKeys } from '../models/config'
import { DateRange, DateUtils } from './date-utils.service'
import { ToggleableModules } from '../features/config/ToggleableModules'

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  stream$: Observable<Config[]>
  private TAG: string = 'ConfigService'
  private defaultConfigs: Config[] = []

  constructor(private feathers: FeathersService) {
    console.log(this.TAG, 'constructor')
    this.init()
    this.stream$.subscribe()
  }

  public getAllWithCaching(): Observable<Config[]> {
    console.log(this.TAG, 'getAllWithCaching')
    return this.stream$
  }

  public getByKey(key: ConfigKeys) {
    return this.getAllWithCaching().pipe(
      tap((configs) => console.log(`${this.TAG}#getByKey`, configs)),
      map((configs) => {
        const res = configs.filter((c) => c.name === key)
        if (Array.isArray(res) && res.length > 0) return res[0]
        else return null
      }),
    )
  }

  public async updateByKey(key: ConfigKeys, value: string) {
    return await this.getByKey(key)
      .pipe(
        first(),
        switchMap((config) => {
          config.value = value
          return this.update(config)
        }),
        first(),
      )
      .toPromise()
  }

  public getValue(name: ConfigKeys): Observable<any> {
    console.log(this.TAG, 'getValue')
    return this.stream$
      .pipe(tap((e) => console.log(e)))
      .pipe(map((configs) => configs.filter((c) => c.name === name)))
      .pipe(
        map((filteredConfig) => {
          if (filteredConfig && filteredConfig.length > 0) {
            return filteredConfig[0].value
          }
          return null
        }),
      )
      .pipe(tap((e) => console.log(e)))
  }

  public getBoolean(name: ConfigKeys): Observable<boolean> {
    return this.getValue(name).pipe(map((val) => JSON.parse(val)))
  }

  private init() {
    console.log(this.TAG, 'init')

    this.initDefaultConfigs()

    this.stream$ = this.feathers
      .get(Config, {})
      .pipe(map((configs) => this.beforeDownHook(configs)))
      .pipe(
        map((configs) => {
          const configsNotOnServer = this.defaultConfigs.filter(
            (defaultConfig) =>
              !configs.some((c) => c.name === defaultConfig.name),
          )
          return configs.concat(configsNotOnServer)
        }),
      )
      .pipe(shareReplay({ bufferSize: 1, refCount: true }))
  }

  private initDefaultConfigs() {
    Object.keys(ConfigKeys).forEach((key) => {
      const c = new Config(ConfigKeys[key])
      this.defaultConfigs.push(c)
    })
  }

  update(config: Config) {
    console.log(this.TAG, 'update', config)
    config = this.beforeUpHook(config)
    return this.feathers.upsert<Config>('configs', config)
  }

  private beforeDownHook(configs: Config[]): Config[] {
    configs.forEach((c) => {
      switch (c.name) {
        case ConfigKeys.ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY:
          c.value = DateUtils.minutesToHours(Number(c.value)).toString()
          break
        case ConfigKeys.ODKDY_JE_POZDE_ZRUSENA_JIZ_POTVRZENA_ASISTENCE_HODINY:
          c.value = DateUtils.minutesToHours(Number(c.value)).toString()
          break
        case ConfigKeys.ORDINACNI_HODINY_START_NO_TZ:
          c.value = DateUtils.militaryTimeToToday(c.value)
          break
        case ConfigKeys.ORDINACNI_HODINY_END_NO_TZ:
          c.value = DateUtils.militaryTimeToToday(c.value)
          break
        case ConfigKeys.TOGGLEABLE_MODULES:
          c.value = new ToggleableModules(JSON.parse(c.value))
          break
      }
    })

    return configs
  }

  private beforeUpHook(c: Config): Config {
    switch (c.name) {
      case ConfigKeys.ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY:
        c.value = DateUtils.hoursToMinutes(Number(c.value)).toString()
        break
      case ConfigKeys.ODKDY_JE_POZDE_ZRUSENA_JIZ_POTVRZENA_ASISTENCE_HODINY:
        c.value = DateUtils.hoursToMinutes(Number(c.value)).toString()
        break
      case ConfigKeys.ORDINACNI_HODINY_START_NO_TZ:
        c.value = DateUtils.getMilitaryTime(new Date(c.value), false)
        break
      case ConfigKeys.ORDINACNI_HODINY_END_NO_TZ:
        c.value = DateUtils.getMilitaryTime(new Date(c.value), false)
        break
      case ConfigKeys.TOGGLEABLE_MODULES:
        console.log(`${this.TAG}#beforeUpHook active_modules`, c)
        // c.value = (c.value as ActiveModules).toJson()
        break
    }

    return c
  }

  // Config values convenience functions

  async getWorkingHours(): Promise<DateRange> {
    return {
      from: new Date(
        await this.getValue(ConfigKeys.ORDINACNI_HODINY_START_NO_TZ)
          .pipe(first())
          .toPromise(),
      ),
      to: new Date(
        await this.getValue(ConfigKeys.ORDINACNI_HODINY_END_NO_TZ)
          .pipe(first())
          .toPromise(),
      ),
    }
  }
  async getLateOrderMinutesToNextMondayLimit() {
    return DateUtils.hoursToMinutes(
      Number(
        await this.getValue(
          ConfigKeys.ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY,
        )
          .pipe(first())
          .toPromise(),
      ),
    )
  }
  async getLateOrderHoursToNextMondayLimit() {
    return Number(
      await this.getValue(ConfigKeys.ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE_HODINY)
        .pipe(first())
        .toPromise(),
    )
  }
  async getLateOrderAssistanceLimitAppliesToCoordinators() {
    return await this.getBoolean(
      ConfigKeys.POZDE_OBJEDNANA_JESTLI_SE_VZTAHUJE_NA_KOORDINATORY,
    )
      .pipe(first())
      .toPromise()
  }
  async getLateCanceledConfirmedAssistanceLimitMinutesToAssistance() {
    return DateUtils.minutesToHours(
      Number(
        await this.getValue(
          ConfigKeys.ODKDY_JE_POZDE_ZRUSENA_JIZ_POTVRZENA_ASISTENCE_HODINY,
        )
          .pipe(first())
          .toPromise(),
      ),
    )
  }
  async getLateCanceledConfirmedAssistanceLimitHoursToAssistance() {
    return Number(
      await this.getValue(
        ConfigKeys.ODKDY_JE_POZDE_ZRUSENA_JIZ_POTVRZENA_ASISTENCE_HODINY,
      )
        .pipe(first())
        .toPromise(),
    )
  }
  async getEditingPastAssistanceByCoordinatorDays() {
    return Number(
      await this.getValue(
        ConfigKeys.DOKDY_JE_MOZNA_EDITACE_PROBEHLYCH_ASISTENCI_DNY,
      )
        .pipe(first())
        .toPromise(),
    )
  }
}
