import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  TAG = 'DebugService'

  performanceCurrentTs = 0
  performanceLastTs = 0

  performanceMeasurements: PerformanceMeasurement[] = []

  constructor() {
    console.log(this.TAG, 'constructor')
  }

  measureSimple() {
    console.log(
      this.TAG,
      'performance diff: ',
      this.performanceCurrentTs - this.performanceLastTs,
      'ms',
    )
    this.performanceLastTs = this.performanceCurrentTs
    this.performanceCurrentTs = performance.now()
  }

  measureStart(tag: string) {
    this.performanceMeasurements = this.performanceMeasurements.filter(
      (pm) => pm.tag !== tag,
    )

    console.log(this.TAG, `Start "${tag}"`)
    this.performanceMeasurements.push({
      tag,
      startTs: performance.now(),
      endTs: 0,
    })
  }

  measureEnd(tag: string) {
    const pm = this.performanceMeasurements.find((pm) => pm.tag === tag)
    if (!pm) {
      console.log('Measurement not started for:', tag)
      return
    }
    pm.endTs = performance.now()
    console.log(this.TAG, `End "${tag}": ${pm.endTs - pm.startTs} ms`)
  }

  static logPerformanceEntries() {
    const measures = performance.getEntriesByType('measure')
    measures.forEach((measureItem) => {
      console.log(`${measureItem.name}: ${measureItem.duration}`)
    })
  }
}

interface PerformanceMeasurement {
  startTs: number
  endTs: number
  tag: string
}
