import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class FloatoverService {
  private readonly showView$: Observable<boolean>
  private showView$$ = new BehaviorSubject(false)

  private readonly contentHtml$: Observable<string>
  private contentHtml$$ = new BehaviorSubject('')

  private readonly loading$: Observable<boolean>
  private loading$$ = new Subject<boolean>()
  private TAG = 'FloatoverService'

  constructor() {
    this.showView$ = this.showView$$.asObservable()
    this.contentHtml$ = this.contentHtml$$.asObservable()
    this.loading$ = this.loading$$.asObservable()
  }

  getFloatoverShowStatus() {
    return this.showView$
  }
  show() {
    // console.log(`${this.TAG}#show`)
    this.showView$$.next(true)
  }
  hide() {
    // console.log(`${this.TAG}#hide`)
    this.showView$$.next(false)
  }

  getContentHtml() {
    return this.contentHtml$
  }
  setContentHtml(contentHtml: string) {
    this.contentHtml$$.next(contentHtml)
  }

  getLoading() {
    return this.loading$
  }
  setLoading(loading: boolean) {
    this.loading$$.next(loading)
  }
}
