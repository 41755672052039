import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core'
import {
  Animation,
  AnimationController,
  Gesture,
  GestureController,
  GestureDetail,
  getPlatforms,
} from '@ionic/angular'

@Component({
  selector: 'app-gesture-refresher',
  templateUrl: './gesture-refresher.component.html',
  styleUrls: ['./gesture-refresher.component.scss'],
})
export class GestureRefresherComponent implements AfterViewInit {
  @ViewChild('icon') iconEl: ElementRef<HTMLDivElement>

  @Input() eventTarget: HTMLElement

  private animation: Animation
  private gesture: Gesture

  private animationStarted: boolean = false
  private initialStep = 0 // px

  public initialIconOffset: -40 // px
  public activeHeight = 60 // px
  public maxTranslate = 220 // px
  public gestureThreshold = 30 // px
  public completeThreshold = 0.67 // 0.0 - 1.0

  public constructor(
    private animationCtrl: AnimationController,
    private gestureCtrl: GestureController,
  ) {}

  ngAfterViewInit() {
    if (GestureRefresherComponent.isDesktop()) {
      return
    }

    this.animation = this.animationCtrl
      .create()
      .addElement(this.iconEl.nativeElement)
      .duration(300)
      .fromTo('opacity', '0.1', '0.8')
      .fromTo(
        'transform',
        'translateY(' + this.initialIconOffset + 'px) rotate(0)',
        'translateY(' + this.maxTranslate + 'px) rotate(537deg)',
      )

    this.gesture = this.gestureCtrl.create({
      el: this.eventTarget,
      direction: 'y',
      threshold: this.gestureThreshold,
      gestureName: 'topSwipeDown',
      canStart: (ev) => this.canStart(ev),
      onMove: (ev) => this.onMove(ev),
      onEnd: (ev) => this.onEnd(ev),
    })
    this.gesture.enable()
  }

  public dispatchGesture() {
    window.location.reload()
  }

  private canStart(ev: GestureDetail) {
    return ev.startY <= this.activeHeight
  }

  private onMove(ev: GestureDetail) {
    if (!this.animationStarted) {
      this.animation.progressStart()
      this.animationStarted = true
    }
    this.animation.progressStep(this.getProgressStep(ev))
  }

  private onEnd(ev: GestureDetail) {
    if (this.animationStarted) {
      this.gesture.enable(false)

      const step = this.getProgressStep(ev)
      const shouldComplete = step >= this.completeThreshold

      this.animation.progressEnd(shouldComplete ? 1 : 0, step).onFinish(() => {
        this.gesture.enable(true)
      })

      this.initialStep = shouldComplete ? this.maxTranslate : 0
      this.animationStarted = false

      if (shouldComplete) {
        this.dispatchGesture()
      }
    }
  }

  private clamp(min, n, max) {
    return Math.max(min, Math.min(n, max))
  }

  private getProgressStep(ev: GestureDetail): number {
    const delta = this.initialStep + ev.deltaY
    return this.clamp(0, delta / this.maxTranslate, 1)
  }

  private static isDesktop() {
    return getPlatforms().includes('desktop')
  }
}
