import { Injectable } from '@angular/core'
import { FeathersService } from '../../services/feathers.service'
import { ClientService, CustomMethod } from '@feathersjs/client'
import { AssistantAssignment } from '../../models/assistantAssignment'
import { Assistance } from '../../models/assistance'
import { AssistantAvailability } from '../../models/assistantAvailability'

interface UpdateData {
  assistanceURID: string
  availabilitiesToSetURIDs: string[]
  nonAvailabilityAssistantsToSetIDs: number[]
}

@Injectable({
  providedIn: 'root',
})
export class AssignmentsManagerService {
  static readonly SERVICE_PATH = 'assignments-manager'
  private readonly service: ClientService & {
    removeAllFromAssistance?: CustomMethod
    advanceStatus?: CustomMethod
    advanceAssistanceStatus?: CustomMethod
    cancelAssistance?: CustomMethod
  } = this.feathersService.service(AssignmentsManagerService.SERVICE_PATH)

  constructor(private feathersService: FeathersService) {}

  /**
   * Remove all assignments from the assistance.
   *
   * @param assistanceId The ID of the assistance.
   * @return A promise that resolves to an array of numbers representing the removed assignment IDs.
   */
  async removeAllAssignmentsFromAssistance(
    assistanceId: number,
  ): Promise<number[]> {
    return await this.service.removeAllFromAssistance(assistanceId)
  }

  async advanceStatus(assignmentIds: number[]): Promise<AssistantAssignment> {
    return await this.service.advanceStatus(assignmentIds)
  }

  async advanceAssistanceStatus(assistanceId: number): Promise<Assistance> {
    return await this.service.advanceAssistanceStatus(assistanceId)
  }

  async cancelAssistance(assistanceId: number): Promise<Assistance> {
    return await this.service.cancelAssistance(assistanceId)
  }

  /**
   * Sets assignments for assistance.
   *
   * @param assistanceURID The URID of the assistance.
   * @param availabilities The list of availabilities to set.
   * @return A promise that resolves to an array of assistant assignments.
   */
  async setAssignments(
    assistanceURID: string,
    availabilities: AssistantAvailability[],
  ): Promise<AssistantAssignment[]> {
    const realAvailabilities = availabilities.filter((a) => a.URID)
    const fakeAvailabilities = availabilities.filter((a) => !a.URID)

    const updateData: UpdateData = {
      assistanceURID,
      availabilitiesToSetURIDs: realAvailabilities.map((a) => a.URID),
      nonAvailabilityAssistantsToSetIDs: fakeAvailabilities.map((a) => {
        return a.assistantId || a.assistant.id
      }),
    }

    return await this.service.update(null, updateData)
  }
}
