import { FeathersModel } from './feathersModel'

export class ContinuingEducationCourse extends FeathersModel {
  static serviceName = 'continuing-education-courses'

  assistantId: string
  name: string
  durationMinutes: number
  realizator: string
  realizationYear: number
}
