import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core'
import { FloatoverService } from './floatover.service'
import { takeUntil } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs'

@Component({
  selector: 'app-floatover',
  templateUrl: './floatover.component.html',
  styleUrls: ['./floatover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatoverComponent implements OnInit, OnDestroy {
  showView: boolean = false
  inputHtml$: Observable<string>
  loading: boolean = true
  private TAG = 'FloatoverComponent'

  private unsubscribe = new Subject<void>()

  @HostBinding('style.top') styleTop = '50%'
  @HostBinding('style.left') styleLeft = '50%'
  @HostBinding('style.width') styleWidth = 'auto'
  @HostBinding('style.height') styleHeight = '100px'
  @HostBinding('style.display') styleDisplay = 'none'

  // width = 150
  height = 150

  private mouseX
  private mouseY
  private boundingRect

  @HostListener('document:mousemove', ['$event']) onMouseMove(e: MouseEvent) {
    // console.log(`${this.TAG}#onMouseMove`)
    this.mouseX = e.pageX
    this.mouseY = e.pageY
    if (this.showView) {
      this.setPosition(this.mouseX, this.mouseY)
      this.setHeight(this.height)
      this.setShow(true)
    }
  }

  constructor(
    private floatoverService: FloatoverService,
    private cdr: ChangeDetectorRef,
    private elRef: ElementRef,
  ) {}

  ngOnInit() {
    this.floatoverService
      .getFloatoverShowStatus()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((show) => {
        if (show) {
          this.refreshPosition()
          this.setShow(true)
        } else {
          this.setShow(false)
        }
      })

    this.inputHtml$ = this.floatoverService.getContentHtml()

    this.floatoverService
      .getLoading()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((loading) => {
        this.loading = loading
        this.refreshPosition()
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  private setPosition(mouseX, mouseY) {
    this.cdr.detectChanges()
    setTimeout(() => {
      this.boundingRect = this.elRef.nativeElement.getBoundingClientRect()
      // console.log(`${this.TAG}#setPosition ${mouseX},${mouseY}`)
      this.styleTop = mouseY + 'px'
      this.styleLeft = mouseX - this.boundingRect.width + 'px'
    })
  }
  private refreshPosition() {
    // console.log(`${this.TAG}#refreshPosition ${this.mouseX},${this.mouseY}`)
    if (this.mouseX && this.mouseY) {
      this.setPosition(this.mouseX, this.mouseY)
    }
  }

  private setHeight(height: number) {
    this.styleHeight = height + 'px'
  }

  private setShow(b: boolean) {
    this.showView = b
    this.styleDisplay = b ? 'block' : 'none'
  }
}
