import { User } from './user'
import { ContinuingEducationCourse } from './continuingEducationCourse'
import { FeathersModel } from './feathersModel'
import { DateUtils } from '../services/date-utils.service'
import { createIntlComparer, DEFAULT_LOCALES } from '../services/utils'

export class Assistant extends FeathersModel {
  static serviceName = 'assistants'
  static intlSortComparer = createIntlComparer(DEFAULT_LOCALES, {
    sensitivity: 'accent',
  })

  title: string
  photoUrl: string
  birthDate: string | Date
  genderId: number
  idCardNumber: number
  nationality: string
  address_street: string
  address_houseNumber: string
  address_city: string
  address_postcode: string

  userId: string
  user: User

  continuing_education_courses: ContinuingEducationCourse[]

  coordinatorNote: string
  isDeactivated: boolean
  deactivatedAt: Date
  deactivationReason: string

  educationalAttainmentId: number
  hasSocialWorkerTraining: boolean

  isAdvanced: boolean

  supervisionHours: number
  hasTrainingCompleted: boolean
  isStrong: boolean

  keyCoordinatorId: number
  keyCoordinator: User

  hasHbvVaccine: boolean = false

  get fullname(): string {
    return this.user.fullName()
  }

  turnSubresourcesIntoFrontendModels() {
    this.user = this.user ? User.createFromProps(User, this.user) : this.user
    this.keyCoordinator = this.keyCoordinator
      ? User.createFromProps(User, this.keyCoordinator)
      : this.keyCoordinator
    if (this.continuing_education_courses) {
      for (let i = 0; i < this.continuing_education_courses.length; i++) {
        this.continuing_education_courses[i] =
          ContinuingEducationCourse.createFromProps(
            ContinuingEducationCourse,
            this.continuing_education_courses[i],
          )
      }
    }
  }

  removeContinuingEducationCourse(id) {
    this.continuing_education_courses =
      this.continuing_education_courses.filter(
        (c: ContinuingEducationCourse) => c.id !== id,
      )
  }

  static sortByName(a: Assistant, b: Assistant) {
    return Assistant.intlSortComparer(
      a.user.lastName + ' ' + a.user.firstName,
      b.user.lastName + ' ' + b.user.firstName,
    )
  }

  isInactive() {
    return DateUtils.canBeParsedToDate(this.deactivatedAt)
  }

  // get isDeactivated() {
  //   return DateUtils.canBeParsedToDate(this.deactivatedAt)
  // }
  //
  // set isDeactivated(value) {
  //   return
  // }
}
