import { ConfigService } from '../services/config.service'
import { DateUtils } from '../services/date-utils.service'
import { Assistance } from '../models/assistance'
import {
  AssistantAssignment,
  AssistantAssignmentList,
} from '../models/assistantAssignment'
import { UsersService } from '../services/domain/users.service'

export class AssistanceHooks {
  constructor(configService: ConfigService, usersService: UsersService) {
    this.configService = configService
    this.usersService = usersService
  }
  configService: ConfigService
  usersService: UsersService
  private TAG: string = 'AssistanceHooks'

  static registerHooks(fc) {
    fc.service(Assistance.serviceName).hooks({
      before: {
        all: [
          // Use normal functions
          // function(context) { console.log('before all hook ran'); }
        ],
        find: [
          // Use ES6 arrow functions
          // context => console.log('before find hook 1 ran'),
          // context => console.log('before find hook 2 ran')
        ],
        get: [
          /* other hook functions here */
        ],
        create: [
          (context) => this.convertAssistantAssignmentListToAssignment(context),
        ],
        update: [
          (context) => this.convertAssistantAssignmentListToAssignment(context),
        ],
        patch: [
          (context) => this.convertAssistantAssignmentListToAssignment(context),
        ],
        remove: [],
      },
      after: {
        all: [
          (context) =>
            this.convertAssignmentToAssistantAssignmentListWrap(context),
        ],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
      },
      error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
      },
    })
  }

  private static convertAssignmentToAssistantAssignmentListWrap(context) {
    console.log('convertAssignmentToAssistantAssignmentListWrap', context)
    if (context.result.data) {
      context.result.data = context.result.data.map((assistance) =>
        this.convertAssignmentToAssistantAssignmentList(assistance),
      )
    } else if (context.result && context.result.assignments) {
      context.result.assistantAssignments =
        AssistantAssignmentList.initializeAsFeathersModels(
          context.result.assignments,
        )
      context.result.assignments = null
      delete context.result.assignments
    }
    if (context.data && context.data.assignments) {
      context.data.assistantAssignments =
        AssistantAssignmentList.initializeAsFeathersModels(
          context.data.assignments,
        )
      context.data.assignments = null
      delete context.data.assignments
    }
    return context
  }

  public static convertAssignmentToAssistantAssignmentList(
    assistance: Assistance,
  ) {
    if (assistance.assignments) {
      assistance.assistantAssignments =
        AssistantAssignmentList.initializeAsFeathersModels(
          assistance.assignments,
        )
      assistance.assignments = null
      delete assistance.assignments
    }
    return assistance
  }

  private static convertAssistantAssignmentListToAssignment(context) {
    if (context.data.assistantAssignments) {
      context.data.assignments =
        context.data.assistantAssignments.assignments.map(
          (a: AssistantAssignment) => {
            const assistantId = a.assistant
              ? a.assistant.id
              : a.assistantId || null
            const assistantAvailabilityId = a.assistant_availability
              ? a.assistant_availability.id
              : a.assistantAvailabilityId || null
            const statusId = a.status ? a.status.id : a.statusId || null

            const res = {
              id: a.id || null,
              statusId,
              assistanceId: context.data.id,
              assistantId,
              assistantAvailabilityId,
            }
            if (a.assistant_availability) {
              res.assistantId = a.assistant_availability.assistant.id
            }
            return res
          },
        )
    }
    return context
  }

  // ODKDY_JE_POZDE_OBJEDNANA_ASISTENCE
  async lateOrderedAssistanceCheck(
    assistance: Assistance,
  ): Promise<Assistance> {
    assistance.isLateOrder = await AssistanceHooks.isAssistanceLateOrdered(
      assistance.getDateFrom(),
      this.usersService,
      this.configService,
    )
    return assistance
  }

  /**
   * Determines if assistance is considered late for an order based on the planned start time.
   *
   * @param plannedFrom - The planned start time of the assistance.
   * @param usersService - The UsersService instance used to get the current user.
   * @param configService - The ConfigService instance used to get configuration values.
   * @returns A promise that resolves to a boolean indicating whether assistance is late or not.
   */
  static async isAssistanceLateOrdered(
    plannedFrom: Date,
    usersService: UsersService,
    configService: ConfigService,
  ): Promise<boolean> {
    const user = await usersService.getCurrentUserPromise()
    if (
      user.roles.client ||
      (user.roles.coordinator &&
        (await configService.getLateOrderAssistanceLimitAppliesToCoordinators()))
    ) {
      const timeToMondayOfAssistance = DateUtils.minutesToDateTime(
        DateUtils.getWeekStart(plannedFrom),
      )
      const limit = await configService.getLateOrderMinutesToNextMondayLimit()

      console.log('isAssistanceLateOrdered', timeToMondayOfAssistance, limit)

      return timeToMondayOfAssistance < limit
    }

    return false
  }
}
