import { FeathersModel } from './feathersModel'

export class AssistanceStatus extends FeathersModel {
  static serviceName = 'assistance-statuses'

  name: AssistanceStatusName
  color: string
  humanReadableName: string

  get contrastBWColor(): string {
    switch (this.color) {
      case 'yellow':
        return 'black'
    }
    return 'white'
  }

  // Positive statuses are all except canceled/canceled_late
  getPositiveStatusOrder() {
    return AssistanceStatus.getOrderOfPositiveStatuses(this.name)
  }

  /**
   * Checks whether current status is equal or larger in order than testStatusName. Tests only for positive statuses.
   */
  isAtLeast(testStatusName: AssistanceStatusName) {
    return (
      this.getPositiveStatusOrder() >=
      AssistanceStatus.getOrderOfPositiveStatuses(testStatusName)
    )
  }

  static getOrderOfPositiveStatuses(name: AssistanceStatusName) {
    switch (name) {
      case AssistanceStatusName.PLANNED:
        return 0
      case AssistanceStatusName.HAS_ASSISTANT:
        return 1
      case AssistanceStatusName.SENT_TO_ASSISTANT:
        return 2
      case AssistanceStatusName.CONFIRMED:
        return 3
      default:
        return undefined
    }
  }

  /**
   * Sorts an array of AssistanceStatus objects based on the order of positive statuses.
   */
  static sortPositiveStatuses(
    statuses: AssistanceStatus[],
  ): AssistanceStatus[] {
    return statuses
      .map((status) => ({
        name: status,
        order: this.getOrderOfPositiveStatuses(status.name),
      }))
      .filter((item) => item.order !== undefined)
      .sort((a, b) => a.order - b.order)
      .map((item) => item.name)
  }

  isNegative() {
    return (
      this.name === AssistanceStatusName.CANCELED ||
      this.name === AssistanceStatusName.CANCELED_LATE
    )
  }

  isPositive() {
    return !this.isNegative()
  }

  isOneOf(statusNames: AssistanceStatusName[]) {
    return statusNames.includes(this.name)
  }

  static allowsCancelation(status: AssistanceStatus) {
    return (
      status.name !== AssistanceStatusName.CANCELED &&
      status.name !== AssistanceStatusName.CANCELED_LATE &&
      status.name !== AssistanceStatusName.PLANNED
    )
  }

  static allowsDeletion(status: AssistanceStatus) {
    return (
      status.name === AssistanceStatusName.PLANNED ||
      status.name === AssistanceStatusName.HAS_ASSISTANT
    )
  }
}

export enum AssistanceStatusName {
  PLANNED = 'noAssistant',
  HAS_ASSISTANT = 'hasAssistant',
  SENT_TO_ASSISTANT = 'sentToAssistant',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  CANCELED_LATE = 'canceledLate',
}
