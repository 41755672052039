import { Injectable } from '@angular/core'
import { AppVisibilityService } from '../app-visibility.service'
import { map, switchMap } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'
import { AdvancedToasterService } from '../../features/notification/advancedToast/advanced-toaster.service'
import { coerceToArray } from '../utils'

@Injectable({
  providedIn: 'root',
})
export class ConnectionAlertService {
  private currentToast: HTMLIonModalElement
  private isConnected$$ = new BehaviorSubject(true)
  private isConnected$ = this.isConnected$$.asObservable()
  private isAppInForeground$ = this.appVisibilityService.visibilityChange$
  private connectedStateWhenAppLastWentToBackground: boolean = true
  private firstToastShown = false

  private toastEmitter$ = this.isAppInForeground$.pipe(
    map((inForeground) => {
      if (!inForeground) this.saveConnectedState()
      return inForeground
    }),
    switchMap((inForeground) =>
      this.isConnected$.pipe(
        map((isConnected) => {
          if (inForeground) {
            this.showToastIfConnectedStateDiffers()
            this.clearSavedConnectedState()
          }
        }),
      ),
    ),
  )

  constructor(
    private toaster: AdvancedToasterService,
    private appVisibilityService: AppVisibilityService,
  ) {
    this.toastEmitter$.subscribe()
  }

  async showDisconnectionToast() {
    this.firstToastShown = true
    const disconnectedCssClass = 'disconnection-toast'

    if (
      !coerceToArray(this.currentToast?.cssClass).includes(disconnectedCssClass)
    ) {
      console.log('dismissing', this.currentToast)
      await this.currentToast?.dismiss()
      this.currentToast = await this.toaster.present({
        message: `Ztraceno spojení se serverem, navazujeme připojení...`,
        spinner: true,
        cssClasses: [disconnectedCssClass],
      })

      this.currentToast.onDidDismiss().then(() => {
        this.currentToast = undefined
      })
    }
  }
  async showConnectionToast() {
    if (!this.firstToastShown) {
      this.firstToastShown = true
      return
    }

    await this.currentToast?.dismiss()
    this.currentToast = await this.toaster.present({
      message: 'Spojení se serverem navázáno!',
      ionIconName: 'heart',
      cssClasses: ['connected-toast'],
      durationMs: 3000,
    })
  }

  async setState(connected: boolean) {
    this.isConnected$$.next(connected)
  }

  saveConnectedState() {
    this.connectedStateWhenAppLastWentToBackground =
      this.isConnected$$.getValue()
  }

  private async showToastIfConnectedStateDiffers() {
    const isConnected = this.isConnected$$.getValue()

    if (isConnected !== this.connectedStateWhenAppLastWentToBackground) {
      if (isConnected) {
        await this.showConnectionToast()
      } else {
        await this.showDisconnectionToast()
      }
    }
  }

  private clearSavedConnectedState() {
    this.connectedStateWhenAppLastWentToBackground = null
  }
}
