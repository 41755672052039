import { Injectable } from '@angular/core'
import { ConfigService } from '../../services/config.service'
import { Observable } from 'rxjs'
import { Config, ConfigKeys } from '../../models/config'
import { ToggleableModules, ToggleableModulesName } from './ToggleableModules'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ToggleableModulesService {
  constructor(private configService: ConfigService) {}

  getModulesConfig(): Observable<Config> {
    return this.configService.getByKey(ConfigKeys.TOGGLEABLE_MODULES)
  }
  isActive(moduleName: ToggleableModulesName) {
    return this.getModulesConfig().pipe(
      map((config) => {
        const modules: ToggleableModules[] = config.value

        if (!modules.hasOwnProperty(moduleName)) return false
        return modules[moduleName]
      }),
    )
  }
  isAllActive(moduleNames: ToggleableModulesName[]) {
    return this.getModulesConfig().pipe(
      map((config) => {
        const modules: ToggleableModules[] = config.value

        for (const moduleName of moduleNames) {
          if (!modules.hasOwnProperty(moduleName)) return false
          if (!modules[moduleName]) return false
        }
        return true
      }),
    )
  }
}
