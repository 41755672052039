/* eslint-disable @typescript-eslint/dot-notation */
import { SearchUtils } from '../shared/utils/searchUtils'

// Default comparer settings
export const DEFAULT_LOCALES = 'cs'

/**
 * Retrieves the list of property names of a given class.
 *
 * @param typeOfClass - The type of class to describe.
 * @return The list of property names of the class.
 */
export function describeClass(typeOfClass: any) {
  const a = new typeOfClass()
  return Object.getOwnPropertyNames(a)
}

export function readAsDataUrl(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.addEventListener('load', () => {
      resolve(fr.result)
    })
    fr.readAsDataURL(file)
  })
}

export async function downloadFromDataUrl(url, filename) {
  const t = await fetch(url)
  const b = await t.blob()
  const a = document.createElement('a')
  a.href = URL.createObjectURL(b)
  a.setAttribute('download', filename)
  a.click()
}

export async function dataUriToBlob(dataUri): Promise<Blob> {
  return (await fetch(dataUri)).blob()
}

export function searchFilter<T>(
  arrayToSearchOn: T[],
  searchTerm: string,
  searchFields: string[],
): T[] {
  // TODO: should filter on combination of searchFields as well, not only separately
  // maybe check if searchTerm has any spaces, then split into words and try to filter on each one, with OR on result

  if (searchTerm.length === 0 || searchFields.length === 0) {
    return arrayToSearchOn
  }

  return arrayToSearchOn.filter((item) => {
    for (const field of searchFields) {
      try {
        // eslint-disable-next-line no-eval
        const val = eval('item.' + field)
        if (
          val != null &&
          SearchUtils.includeFilterWithReversal(val, searchTerm)
        ) {
          return true
        }
      } catch (e) {
        // don't have to do anything
        console.log(e)
      }
    }
    return false
  })
}

export function shallowCopy(object: any) {
  return Object.assign({}, object)
}

export function shallowCopyArray(array: any[]) {
  return Object.assign([], array)
}

export function cloneClass<T>(original: T): T {
  const copy = new (original.constructor as new () => T)()
  Object.assign(copy, original)
  return copy
}

export function dataURItoImageBlob(dataURI) {
  const byteString = window.atob(dataURI)
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const int8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i)
  }
  const blob = new Blob([int8Array], { type: 'image/jpeg' })
  return blob
}

export function getFileExtension(filename: string): string {
  return filename.substr(filename.lastIndexOf('.') + 1)
}

export const TypeChecker = class {
  static is(obj: any, type: NumberConstructor): obj is number
  static is(obj: any, type: StringConstructor): obj is string
  static is<T>(obj: any, type: { prototype: T }): obj is T
  static is(obj: any, type: any): boolean {
    const objType: string = typeof obj
    const typeString = type.toString()
    const nameRegex: RegExp =
      /Arguments|Function|String|Number|Date|Array|Boolean|RegExp/

    let typeName: string

    if (obj && objType === 'object') {
      return obj instanceof type
    }

    if (typeString.startsWith('class ')) {
      return type.name.toLowerCase() === objType
    }

    typeName = typeString.match(nameRegex)
    if (typeName) {
      return typeName[0].toLowerCase() === objType
    }

    return false
  }

  static checkArrayType(myArray: any[], type: any): boolean {
    return myArray.every((item) => {
      return this.is(item, type)
    })
  }
}

/**
 * Groups data in an array or arbitrary objects by one of its keys.
 * @param data is an array of objects
 * @param key is the key (or property accessor) to group by
 */
export function groupBy<T>(data: T[], key: string) {
  return data.reduce((storage, item) => {
    // get the first instance of the key by which we're grouping
    const group = item[key]
    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || []
    // add this item to its group within `storage`
    storage[group].push(item)
    // return the updated storage to the reduce function, which will then loop through the next
    return storage
  }, {})
}

export function coerceToArray<T>(obj: T | T[]): T[] {
  if (!Array.isArray(obj)) return [obj]
  return obj
}

/**
 * Specify sort order using array. Prepend minus (-) to specify descending order.
 */
export function sortByFields(fields: string[]) {
  // console.debug('sortByFields fields', fields)

  const dir = []
  const l = fields.length
  let i

  fields = fields.map((o, idx) => {
    if (o[0] === '-') {
      dir[idx] = -1
      o = o.substring(1)
    } else {
      dir[idx] = 1
    }
    return o
  })

  // console.debug('sortByFields mapped fields', fields)

  return (a, b) => {
    for (i = 0; i < l; i++) {
      const field = fields[i]

      let aValue = resolvePath(a, field)
      let bValue = resolvePath(b, field)

      if (typeof aValue === 'string')
        aValue = SearchUtils.normalizeForSort(aValue)
      if (typeof bValue === 'string')
        bValue = SearchUtils.normalizeForSort(bValue)

      if (aValue === undefined || aValue === null) aValue = ''
      if (bValue === undefined || bValue === null) bValue = ''

      // console.debug('sortByFields sorting', aValue, bValue)

      if (aValue > bValue) return dir[i]
      if (aValue < bValue) return -dir[i]
    }
    return 0
  }
}

/**
 * Specify sort order using array. Strings will be compared using given locale(s).
 * Prepend minus (-) to specify descending order.
 * @param fields Fields
 * @param locales String or an array of strings specifying desired locale(s).
 * @param collatorOptions Custom collator properties.
 */
export function sortByFieldsIntl(
  fields: string[],
  locales: string | string[],
  collatorOptions: Intl.CollatorOptions = {},
) {
  // console.debug('sortByFieldsIntl fields', fields)

  const dir: number[] = []
  const l = fields.length
  let i

  // For each field, note its sorting order (ASC=1, DESC=-1)
  fields = fields.map((o, idx) => {
    if (o[0] === '-') {
      dir[idx] = -1
      o = o.substring(1)
    } else {
      dir[idx] = 1
    }
    return o
  })

  const collator = new Intl.Collator(locales, collatorOptions)

  // console.debug('sortByFieldsIntl mapped fields', fields)

  return (a, b) => {
    for (i = 0; i < l; i++) {
      const field = fields[i]

      // Get actual values from the objects being compared
      let aValue = resolvePath(a, field)
      let bValue = resolvePath(b, field)

      // Treat nullish values as empty string
      if (aValue === undefined || aValue === null) {
        aValue = ''
      }
      if (bValue === undefined || bValue === null) {
        bValue = ''
      }

      // console.debug('sortByFieldsIntl sorting', aValue, bValue)

      if (typeof aValue === 'string' || typeof bValue === 'string') {
        // Note: Intl.Collator apparently doesn't need unicode strings to be normalized
        const res = collator.compare(aValue, bValue)
        if (res !== 0) {
          return res * dir[i]
        }
      } else {
        // Non-string values will be compared using basic operators. This is to sort numeric values properly.
        if (aValue > bValue) {
          return dir[i]
        }
        if (aValue < bValue) {
          return -dir[i]
        }
      }
    }
    return 0
  }
}

/**
 * Returns an internationalized comparer function for locale-specific string comparisons.
 * @param locales String or an array of strings specifying desired locale(s).
 * @param collatorOptions Custom collator properties.
 */
export function createIntlComparer(
  locales: string | string[],
  collatorOptions: Intl.CollatorOptions = {},
) {
  const descending = !!collatorOptions['descending']
  delete collatorOptions['descending']

  const collator = new Intl.Collator(locales, collatorOptions)
  const direction = descending ? -1 : 1

  return (a: any, b: any) => {
    if (typeof a !== 'string') {
      a = a === undefined || a === null ? '' : String(a)
    }
    if (typeof b !== 'string') {
      b = b === undefined || b === null ? '' : String(b)
    }
    // Note: Intl.Collator apparently doesn't need unicode strings to be normalized
    return direction * collator.compare(a, b)
  }
}

export function resolvePath(object: object, path: string, defaultValue = null) {
  return path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object)
}

export function romanToNumber(roman: string) {
  function charToNumber(c) {
    switch (c) {
      case 'I':
        return 1
      case 'V':
        return 5
      case 'X':
        return 10
      case 'L':
        return 50
      case 'C':
        return 100
      case 'D':
        return 500
      case 'M':
        return 1000
      default:
        return 0
    }
  }

  if (roman == null) return 0
  let num = charToNumber(roman.charAt(0))
  let pre, curr

  for (let i = 1; i < roman.length; i++) {
    curr = charToNumber(roman.charAt(i))
    pre = charToNumber(roman.charAt(i - 1))
    if (curr <= pre) {
      num += curr
    } else {
      num = num - pre * 2 + curr
    }
  }
  return num
}

/**
 * Determines whether more than one variable is defined.
 *
 * @param variables - The variables to check.
 * @return True if more than one variable is defined, false otherwise.
 */
export function moreThanOneDefined(...variables: any[]): boolean {
  return variables.filter((v) => v !== undefined).length > 1
}
