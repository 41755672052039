import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular'

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(private navCtrl: NavController) {}

  async goToAssistantProfile(assistantId: number) {
    await this.navCtrl.navigateForward(
      `/menu/app/tabs/assistants/assistant/${assistantId}/basic-info`,
    )
  }

  async goToLogin() {
    await this.navCtrl.navigateRoot('/login')
  }
}
