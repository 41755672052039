import { LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'

import { FeathersService } from './services/feathers.service'
import { AuthGuardService } from './services/guards/auth-guard.service'
import { NonAuthGuardService } from './services/guards/non-auth-guard.service'
import { ToasterService } from './features/notification/toaster.service'
import { DebugService } from './services/debug/debug.service'
import { CommonModule, registerLocaleData } from '@angular/common'
import localeCs from '@angular/common/locales/cs'
import { NoopAnimationsModule } from '@angular/platform-browser/animations'
import { ProgressBarModalModule } from './features/notification/progressBarManager/progress-bar-modal/progress-bar-modal.module'
import { SuperadminConsoleComponent } from './pages/superadmin/superadmin-console/superadmin-console.component'
import { FloatoverModule } from './features/floatover/floatover.module'
import { ActiveModuleGuardService } from './services/guards/active-module-guard.service'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { GestureRefresherComponent } from './components/gesture-refresher/gesture-refresher.component'
import { ConnectionAlertService } from './services/connectionAlert/connection-alert.service'

registerLocaleData(localeCs)

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    SuperadminConsoleComponent,
    GestureRefresherComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      animated: false,
      navAnimation: null,
      innerHTMLTemplatesEnabled: true,
      mode: 'md',
    }),
    AppRoutingModule,
    CommonModule,
    ProgressBarModalModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    HttpClientModule,
    FloatoverModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FeathersService,
    ConnectionAlertService,
    AuthGuardService,
    NonAuthGuardService,
    ActiveModuleGuardService,
    ToasterService,
    DebugService,
    { provide: LOCALE_ID, useValue: 'cs' },
  ],
})
export class AppModule {}
