import { merge, MonoTypeOperatorFunction, Observable } from 'rxjs'
import { debounceTime, tap, throttleTime } from 'rxjs/operators'

export function throunceTime<T>(duration: number): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) =>
    merge(
      source.pipe(throttleTime(duration)),
      source.pipe(debounceTime(duration)),
    ).pipe(throttleTime(0, undefined, { leading: true, trailing: false }))
}

export function log<T>(
  ...message: any[]
): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> =>
    source.pipe(
      tap((value) => {
        if (message) {
          console.log(`${message}:`, value)
        } else {
          console.log(value)
        }
      }),
    )
}
