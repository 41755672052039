import { generatePermutations } from './arrayUtils'

export class SearchUtils {
  static normalizeForSort(str: string) {
    return str.toLocaleLowerCase().normalize('NFD')
  }

  static normalizeForSearch(str: string) {
    return str
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  static includeFilter(fieldValue: string, searchTerm: string): boolean {
    if (!fieldValue) return false
    return SearchUtils.normalizeForSearch(fieldValue).includes(
      SearchUtils.normalizeForSearch(searchTerm),
    )
  }

  /**
   * Checks if a string field value includes a search term. Provides the option to reverse
   * the search logic by comparing the normalized field value with permutations of the
   * normalized search words.
   */
  static includeFilterWithReversal(fieldValue: string, searchTerm: string) {
    if (!fieldValue) return false

    const searchWords = SearchUtils.normalizeForSearch(searchTerm).split(' ')

    if (searchWords.length === 1) {
      return SearchUtils.normalizeForSearch(fieldValue).includes(searchWords[0])
    } else {
      const normalizedFieldValue = SearchUtils.normalizeForSearch(fieldValue)
      const permutations = generatePermutations(searchWords)
      return permutations.some((permutation) =>
        normalizedFieldValue.includes(permutation),
      )
    }
  }
}
