import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base'

declare var require: any

export const DELETE_TITLE = 'Smazat událost'
export const EDIT_TITLE = 'Upravit událost'
export const DAY = 'Den'

export function ejsSchedulerInit() {
  console.log('ejsSchedulerInit')

  setCulture('cs')

  loadCldr(
    require('cldr-data/main/cs/numbers.json'),
    require('cldr-data/main/cs/ca-gregorian.json'),
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/cs/timeZoneNames.json'),
  )

  L10n.load({
    cs: {
      schedule: {
        day: DAY,
        week: 'Týden',
        workWeek: 'Pracovní týden',
        month: 'Měsíc',
        agenda: 'Přehled',
        weekAgenda: 'Týdenní přehled',
        workWeekAgenda: 'Přehled pracovního týdne',
        monthAgenda: 'Měsíční přehled',
        today: 'Dnes',
        noEvents: 'Žádné události',
        emptyContainer: 'Na tento den nejsou naplánovány žádné události.',
        allDay: 'Celý den',
        start: 'Začátek',
        end: 'Konec',
        more: 'Více',
        close: 'Zavřít',
        cancel: 'Zrušit',
        noTitle: '(Bez názvu)',
        delete: 'Smazat',
        deleteEvent: 'Smazat událost',
        deleteMultipleEvent: 'Smazat více událostí',
        selectedItems: 'Zvolené položky',
        deleteSeries: 'Smazat opakování',
        edit: 'Upravit',
        editSeries: 'Upravit opakování',
        editEvent: 'Upravit událost',
        createEvent: 'Vytvořit',
        subject: 'Předmět',
        addTitle: 'Přidat název',
        moreDetails: 'Více detailů',
        save: 'Uložit',
        editContent:
          'Chceš upravit pouze tuto událost, nebo všechny události v opakování?',
        deleteRecurrenceContent:
          'Chceš smazat pouze tuto událost, nebo všechny události v opakování?',
        deleteContent: 'Vskutku chceš smazat tuto událost?',
        deleteMultipleContent:
          'Vskutku chceš smazat vybrané události? Jako vopravdicky jo?',
        newEvent: 'Nová událost',
        title: 'Název',
        location: 'Místo',
        description: 'Popis',
        timezone: 'Časové pásmo',
        startTimezone: 'Začátek časového pásma',
        endTimezone: 'Konec časového pásma',
        repeat: 'Opakovat',
        saveButton: 'Uložit',
        cancelButton: 'Zrušit',
        deleteButton: 'Smazat',
        recurrence: 'Opakování',
        wrongPattern: 'Nastavení opakování je neplatné.',
        seriesChangeAlert:
          'Změny provedené na určitých výskytech v tomto opakování budou zrušeny a události budou opět odpovídat opakování.',
        createError:
          'Trvání události musí být kratší než frekvence jejího výskytu. Zkrať dobu trvání nebo změň nastavení opakování.',
        recurrenceDateValidation:
          'Některé měsíce mají méně dnů než zvolené datum. Pro tyto měsíce bude opakování spadat na poslední den v měsíci.',
        sameDayAlert:
          'Dva výskyty stejné události se nemohou vyskytovat ve stejný den.',
        editRecurrence: 'Upravit opakování',
        repeats: 'Opakování',
        alert: 'Upozornění',
        startEndError: 'Zvolené datum konce je před datem začátku.',
        invalidDateError: 'Zadané datum je neplatné.',
        ok: 'Ok',
        occurrence: 'Výskyt',
        series: 'Opakování',
        previous: 'Předchozí',
        next: 'Další',
        timelineDay: 'Časová osa - Den',
        timelineWeek: 'Časová osa - Týden',
        timelineWorkWeek: 'Časová osa - Pracovní týden',
        timelineMonth: 'Časová osa - Měsíc',
        timelineYear: 'Časová osa - Rok',
        editFollowingEvent: 'Tuto a následující',
        deleteTitle: DELETE_TITLE,
        editTitle: EDIT_TITLE,
      },
      recurrenceeditor: {
        none: 'Žádné',
        daily: 'Denně',
        weekly: 'Týdně',
        monthly: 'Měsíčně',
        month: 'Měsíc',
        yearly: 'Ročně',
        never: 'Nikdy',
        until: 'Do',
        count: 'Počet',
        first: '1.',
        second: '2.',
        third: '3.',
        fourth: '4.',
        last: 'Poslední',
        repeat: 'Opakovat',
        repeatEvery: 'Opakovat každý',
        on: 'Opakovat',
        end: 'Konec',
        onDay: 'Dne',
        days: DAY,
        weeks: 'Týden',
        months: 'Měsíc',
        years: 'Rok',
        every: 'každý',
        summaryTimes: 'time(s)',
        summaryOn: 'on',
        summaryUntil: 'until',
        summaryRepeat: 'Opakovat každý',
        summaryDay: DAY,
        summaryWeek: 'Týden',
        summaryMonth: 'Měsíc',
        summaryYear: 'Rok',
      },
    },
  })
}
