import {
  FeathersDataStoreHolder,
  FeathersFrontendModelFactory,
  FeathersRecord,
} from './feathers.service'
import { defer, from, merge, Observable } from 'rxjs'
import { debounceTime, filter, map, shareReplay, tap } from 'rxjs/operators'
import { FeathersBaseDataStore } from './feathersBaseDataStore'
import { Paginated } from '@feathersjs/feathers'
import { NewableFeathersModel } from '../shared/utils/typeUtils'
import { FeathersModel, ModelEventSource } from '../models/feathersModel'

export class FeathersDataStore<
  T extends FeathersModel,
> extends FeathersBaseDataStore<T, T[]> {
  defaultStoreValue: T[] = []
  private factory: FeathersFrontendModelFactory<T> =
    new FeathersFrontendModelFactory(this.recordClass)

  constructor(
    protected recordClass: NewableFeathersModel<T>,
    protected feathersService: any,
    dataStoreHolder: FeathersDataStoreHolder,
  ) {
    super(feathersService, dataStoreHolder)
  }

  public find(query): Observable<T[]> {
    this.query = query

    const dataFromFind$: Observable<T[]> = defer(() =>
      from(this.feathersService.find({ query })),
    ).pipe(
      // tap(e => console.log('got from find', e)),
      // first(),
      map((response: Paginated<FeathersRecord> | FeathersRecord[]) => {
        // console.log('response', response)
        let records
        if (response !== null && 'data' in response) {
          records = response?.data || this.defaultStoreValue
        } else {
          records = (response as T[]) || this.defaultStoreValue
        }
        console.log(
          `On find ${this.id}/${this.feathersService.path}:`,
          new Date().getTime(),
          'with query:',
          query,
          'result:',
          response,
        )
        this.store = records.map((record) => this.mapToModel(record, 'find'))
        return this.store
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    )

    const events$ = merge(
      this.created$,
      this.updated$,
      this.removed$,
      this.patched$,
    ).pipe(
      tap((values) =>
        console.log(
          `${this.TAG} ${this.id}/${this.feathersService.path}: new event: values`,
          values,
        ),
      ),
      debounceTime(100),
    )

    return merge(dataFromFind$, events$).pipe(
      tap((values) =>
        console.log(
          this.TAG,
          `${this.id}/${this.feathersService.path} find() before filter, values:`,
          values,
          this,
        ),
      ),
      debounceTime(100),
      shareReplay({ refCount: true, bufferSize: 1 }),
      tap((values) =>
        console.log(
          this.TAG,
          `${this.id}/${this.feathersService.path} find() right before filter, values:`,
          values,
          this,
        ),
      ),
      map((values) => {
        if (values === undefined) {
          return this.store
        }
        return values
      }),
      filter((values) => values !== undefined),
      tap((values) =>
        console.log(
          this.TAG,
          `${this.id}/${this.feathersService.path} find(), values:`,
          values,
          this,
        ),
      ),
      tap((_) => (this.allowEvents = true)),
    )
  }

  protected onCreated(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onCreated`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    if (record == null) {
      console.log('Null record returned')
      return
    }
    const index = this.getIndex(record.id)
    if (index >= 0) {
      this.store[index] = this.mapToModel(record, 'create')
    } else {
      this.store?.push(this.mapToModel(record, 'create'))
    }
    return this.store
  }
  protected onUpdated(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onUpdated record:`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    const index = this.getIndex(record.id)
    if (index >= 0) {
      this.store[index] = this.mapToModel(record, 'update')
    } else {
      this.store.push(this.mapToModel(record, 'update'))
    }
    return this.store
  }
  protected onPatched(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onPatched record:`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    const index = this.getIndex(record.id)
    if (index >= 0) {
      this.store[index] = this.mapToModel(record, 'patch')
      return this.store
    }
  }
  protected onRemoved(record: T): T[] {
    console.log(
      this.TAG,
      `${this.id}/${this.feathersService.path} onRemoved record:`,
      record,
      'dataStore:',
      this.store,
      this,
    )
    const index = this.getIndex(record.id)
    if (index >= 0) {
      this.store.splice(index, 1)
      return this.store
    }
  }

  protected getIndex(id: number | string): number {
    console.log('DataStore#getIndex', this.id, this.store, this)
    if (this.store) {
      for (let i = 0; i < this.store.length; i++) {
        if (this.store[i].id === id) {
          return i
        }
      }
    }
    return -1
  }

  protected mapToModel(record, eventSource: ModelEventSource): T {
    const model = this.factory.generateModelInstanceFromFeathersData(record)
    model.eventSource = eventSource
    return model
  }
}
