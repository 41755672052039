import { ConfigService } from '../services/config.service'
import {
  AssistantAssignment,
  AssistantAssignmentList,
} from '../models/assistantAssignment'
import { AssistantAvailability } from '../models/assistantAvailability'

export class AssistantAvailabilityHooks {
  constructor(configService: ConfigService) {
    this.configService = configService
  }
  configService: ConfigService

  private static TAG: string = 'AssistantAvailabilityHooks'

  static registerHooks(fc) {
    fc.service(AssistantAvailability.serviceName).hooks({
      before: {
        all: [
          // Use normal functions
          // function(context) { console.log('before all hook ran'); }
        ],
        find: [
          // Use ES6 arrow functions
          // context => console.log('before find hook 1 ran'),
          // context => console.log('before find hook 2 ran')
        ],
        get: [
          /* other hook functions here */
        ],
        create: [
          (context) => this.convertAssistantAssignmentListToAssignment(context),
        ],
        update: [
          (context) => this.convertAssistantAssignmentListToAssignment(context),
        ],
        patch: [
          (context) => this.convertAssistantAssignmentListToAssignment(context),
        ],
        remove: [],
      },
      after: {
        all: [
          (context) =>
            this.convertAssignmentToAssistantAssignmentListWrap(context),
        ],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
      },
      error: {
        all: [],
        find: [],
        get: [],
        create: [],
        update: [],
        patch: [],
        remove: [],
      },
    })
  }

  private static convertAssignmentToAssistantAssignmentListWrap(context) {
    console.log('convertAssignmentToAssistantAssignmentListWrap', context)
    if (context.result.data) {
      context.result.data = context.result.data.map((availability) =>
        this.convertAssignmentToAssistantAssignmentList(availability),
      )
    } else if (context.result && context.result.assignments) {
      context.result.assistantAssignments =
        AssistantAssignmentList.initializeAsFeathersModels(
          context.result.assignments,
        )
      context.result.assignments = null
      delete context.result.assignments
    }
    if (context.data && context.data.assignments) {
      context.data.assistantAssignments =
        AssistantAssignmentList.initializeAsFeathersModels(
          context.data.assignments,
        )
      context.data.assignments = null
      delete context.data.assignments
    }
    return context
  }

  public static convertAssignmentToAssistantAssignmentList(
    availability: AssistantAvailability,
  ) {
    if (availability.assignments) {
      availability.assistantAssignments =
        AssistantAssignmentList.initializeAsFeathersModels(
          availability.assignments,
        )
      availability.assignments = null
      delete availability.assignments
    }
    return availability
  }

  private static convertAssistantAssignmentListToAssignment(context) {
    console.log('convertAssistantAssignmentListToAssignment', context)
    if (context.data.assistantAssignments) {
      context.data.assignments =
        context.data.assistantAssignments.assignments.map(
          (a: AssistantAssignment) => {
            const res = {
              id: a.id || null,
              statusId: a.status ? a.status.id : null,
              assistanceId: context.data.id,
              assistantId: a.assistant ? a.assistant.id : null,
              assistantAvailabilityId: a.assistant_availability
                ? a.assistant_availability.id
                : null,
            }
            if (a.assistant_availability) {
              res.assistantId = a.assistant_availability.assistantId
            }
            return res
          },
        )
    }
    return context
  }
}
